import { Box, Button, Typography } from '@mui/material'
import { Fragment } from 'react'

const BlockUser = ({ isBlocked, handleClick }) => {
    return (
        <Fragment>
            {isBlocked === 1
                ? <Box >
                    <Button variant='outlined' fullWidth onClick={() => handleClick({ isBlocked: 0 })}>Unblock</Button>
                    <Typography variant='body2' textAlign='center' fontWeight='bold' color='warning.main' pt={1}>User is currently blocked.</Typography>
                </Box>
                : <Button variant='outlined' fullWidth onClick={() => handleClick({ isBlocked: 1 })}>Block User</Button>
            }
        </Fragment>
    )
}

export default BlockUser