import { CardMedia } from "@mui/material";
import logo from "assets/logo.jpg";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();

  return (
    <CardMedia
      sx={{
        width: 100,
        height: "auto",
        cursor: "pointer",
      }}
      image={logo}
      component="img"
      onClick={() => navigate("/")}
    />
  );
};

export default Logo;
