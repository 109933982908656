import { Routes, Route } from "react-router-dom";
import UserLayout from "Core/Layout/UserLayout";
import Home from "Modules/User/Pages/Home/Home";
import LogIn from "Modules/Auth/LogIn";
import ForgotPassword from "Modules/Auth/ForgotPassword";
import ResetPassword from "Modules/Auth/ResetPassword";
import Unauthorized from "Core/Unauthorized";
import AboutUs from "./Pages/AboutUs/AboutUs";
import OurTeam from "./Pages/OurTeam/OurTeam";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Products from "./Pages/Product/Products";
import Career from "./Pages/Career/Career";
import CameraInstallation from "./Pages/Services/CameraInstallation";
import MonitoringSolutions from "./Pages/Services/MonitoringSolutions";
import MaintenanceAndSupport from "./Pages/Services/Maintenance&Support";
import ConsultationAndSecurityPlanning from "./Pages/Services/Consultation&SecurityPlanning";
import PersonalSecurity from "./Pages/Sectors/PersonalSecurity";
import CommercialSecurity from "./Pages/Sectors/CommercialSecurity";
import PropertySecurity from "./Pages/Sectors/PropertySecurity";

const UserRoutes = () => {
  return (
    <Routes>
      <Route element={<UserLayout />}>
        <Route index element={<Home />} />
        <Route path="login" element={<LogIn />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        <Route path="products" element={<Products />} />
        <Route path="career" element={<Career />} />

        <Route path="camera-installation" element={<CameraInstallation />} />
        <Route path="monitoring-solutions" element={<MonitoringSolutions />} />
        <Route path="maintenance-support" element={<MaintenanceAndSupport />} />
        <Route
          path="consultation-security-planning"
          element={<ConsultationAndSecurityPlanning />}
        />

        <Route path="personal" element={<PersonalSecurity />} />
        <Route path="commercial" element={<CommercialSecurity />} />
        <Route path="property" element={<PropertySecurity />} />

        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="our-team" element={<OurTeam />} />
      </Route>
    </Routes>
  );
};

export default UserRoutes;
