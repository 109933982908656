import AdminLayout from "Core/Layout/AdminLayout";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "Modules/Admin/Pages/Dashboard/Dashboard";

import RoleChecker from "Services/RoleChecker";
import { USER_ROLE_REVERSE } from "Helpers/UserRole";
import UserList from "./User/UserList";
import AddUser from "./User/AddUser";
import UserManage from "./User/UserManage";
import ChangePassword from "./User/ChangePassword";
import Profile from "Modules/Admin/Profile";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<RoleChecker allowedRoles={[USER_ROLE_REVERSE.admin]} />}>
        <Route element={<AdminLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="user">
            <Route index element={<UserList />} />
            <Route path="add" element={<AddUser />} />
            <Route path="manage">
              <Route index element={<UserManage />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
