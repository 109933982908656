import React from "react";
import { Box, Container, Typography, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTheme, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";

const CarouselItem = ({ title, description, buttonText, buttonLink }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Typography
          component="h1"
          variant={isMobile ? "h4" : "h2"}
          align="center"
          color="secondary"
          fontWeight="bold"
          gutterBottom
        >
          {title}
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          paragraph
        >
          {description}
        </Typography>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button
            component={Link}
            to={buttonLink}
            variant="contained"
            color="secondary"
            endIcon={<ArrowForwardIcon />}
          >
            {buttonText}
          </Button>
        </Stack>
      </motion.div>
    </motion.div>
  );
};

const HomeCarousel = ({ carouselItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        pt: 1,
        pb: 6,
        background: `linear-gradient(rgba(255,0,0,0.3), rgba(255,255,255,0.7))`,
        py: isMobile ? 22 : isTablet ? 22 : 22,
        width: "100%",
      }}
    >
      <Container maxWidth="xl">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
        >
          {carouselItems.map((item, index) => (
            <CarouselItem key={index} {...item} />
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};

export default HomeCarousel;
