import { Card, Typography } from '@mui/material'

const UserItem = ({ title, totalValue }) => {
    return (
        <Card sx={{
            boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
            p: 4,
            textAlign: 'center',
        }}
        >
            <Typography variant='h6' fontWeight='bold' sx={{ letterSpacing: 1.5, lineHeight: 1.5 }}>
                {title}
            </Typography>
            <Typography variant='h6' fontWeight='bold' color='primary'>{totalValue}</Typography>
        </Card>
    )
}

export default UserItem