import React from "react";
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  Avatar,
  Rating,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { styled } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  position: "relative",
  "& .carousel .slide": {
    padding: "0 10px",
    transition: "all .5s",
    opacity: 0.3,
  },
  "& .carousel .slide.selected": {
    opacity: 1,
  },
  "& .carousel .control-arrow": {
    display: "none",
  },
}));

const SliderButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  opacity: 0.5,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
    opacity: 0.8,
  },
  height: "40px",
  width: "40px",
  borderRadius: "50%",
}));

const Testimonial = ({ item }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ height: "100%" }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          textAlign: "center",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Avatar
            src={item.image}
            alt={item.name}
            sx={{
              width: 60,
              height: 60,
              margin: "0 auto",
              mb: 1,
              bgcolor: theme.palette.secondary.main,
            }}
          />
          <Typography variant={isMobile ? "body1" : "h6"} gutterBottom>
            {item.name}
          </Typography>
          <Rating value={item.rating} readOnly size="small" sx={{ mb: 1 }} />
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontStyle: "italic",
            fontSize: isMobile ? "0.8rem" : "1rem",
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          "{item.testimonial}"
        </Typography>
        <Typography variant="caption" sx={{ mt: 1, color: "text.secondary" }}>
          {item.position}
        </Typography>
      </Paper>
    </motion.div>
  );
};

const Testimonials = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const testimonials = [
    {
      name: "John Smith",
      position: "Business Owner",
      testimonial:
        "The security solutions provided by Ulka Digital Security have given me complete peace of mind. Their professional team and cutting-edge technology have exceeded my expectations.",
      rating: 5,
      image: "",
    },
    {
      name: "Sarah Johnson",
      position: "Office Manager",
      testimonial:
        "Exceptional service and support. The team was incredibly helpful throughout the installation process and their ongoing support has been outstanding.",
      rating: 5,
      image: "",
    },
    {
      name: "Michael Brown",
      position: "Property Manager",
      testimonial:
        "I highly recommend their services. The quality of their security systems and the expertise of their staff are unmatched in the industry.",
      rating: 5,
      image: "",
    },
  ];

  const getCarouselSettings = () => {
    return {
      centerSlidePercentage: isMobile ? 80 : 33.33,
      centerMode: true,
      showArrows: false,
      showStatus: false,
      showIndicators: false,
      infiniteLoop: true,
      emulateTouch: true,
      swipeable: true,
      dynamicHeight: false,
    };
  };

  const carouselRef = React.useRef(null);

  const handlePrev = () => {
    carouselRef.current.onClickPrev();
  };

  const handleNext = () => {
    carouselRef.current.onClickNext();
  };

  return (
    <Container maxWidth="lg">
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        align="center"
        color={theme.palette.secondary.main}
        gutterBottom
        sx={{ mb: 3 }}
      >
        What Our Clients Say
      </Typography>

      <Box sx={{ maxWidth: "100%", margin: "0 auto", position: "relative" }}>
        {!isMobile && (
          <SliderButton onClick={handlePrev} sx={{ left: -20 }}>
            <ArrowBackIosNewIcon />
          </SliderButton>
        )}
        <StyledCarousel {...getCarouselSettings()} ref={carouselRef}>
          {testimonials.map((item, index) => (
            <Testimonial key={index} item={item} />
          ))}
        </StyledCarousel>
        {!isMobile && (
          <SliderButton onClick={handleNext} sx={{ right: -20 }}>
            <ArrowForwardIosIcon />
          </SliderButton>
        )}
      </Box>
    </Container>
  );
};

export default Testimonials;
