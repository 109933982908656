import React from "react";
import {
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  Grid,
} from "@mui/material";
import { motion } from "framer-motion";

const ServiceItem = ({ title, description, imageSrc, imageAlt, index }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const isEven = index % 2 === 0;

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 0}
      alignItems="center"
      direction={isEven ? "row" : "row-reverse"}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          pr: isEven && !isMobile ? 2 : 0,
          pl: !isEven && !isMobile ? 2 : 0,
        }}
      >
        <motion.div
          initial={{ opacity: 0, x: isEven ? -50 : 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"}
            color={theme.palette.secondary.main}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography variant={isMobile ? "body2" : "body1"}>
            {description}
          </Typography>
        </motion.div>
      </Grid>
      <Grid item xs={12} md={6}>
        <motion.div
          initial={{ opacity: 0, x: isEven ? 50 : -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Box
            component="img"
            src={imageSrc}
            alt={imageAlt}
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: 0,
              boxShadow: 3,
              maxHeight: isTablet ? "300px" : "400px",
              objectFit: "cover",
            }}
          />
        </motion.div>
      </Grid>
    </Grid>
  );
};

const HomeServices = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const services = [
    {
      title: "Camera Installation",
      description:
        "We offer high-definition CCTV cameras with remote-access capabilities, night vision, and motion detection features. Our expert team ensures optimal placement for comprehensive coverage of your property.",
      imageSrc: require("assets/service/service1.png"),
      imageAlt: "Camera Installation",
    },
    {
      title: "Monitoring Solutions",
      description:
        "Our monitoring solutions include live monitoring services, cloud storage options for footage, and alerts for suspicious activities. We provide 24/7 surveillance to keep your property protected around the clock.",
      imageSrc: require("assets/service/service2.png"),
      imageAlt: "Monitoring Solutions",
    },
    {
      title: "Maintenance and Support",
      description:
        "We offer regular system checks, updates, and 24/7 customer support. Our team ensures your security system is always functioning at its best, providing you with peace of mind.",
      imageSrc: require("assets/service/service3.png"),
      imageAlt: "Maintenance and Support",
    },
    {
      title: "Consultation and Security Planning",
      description:
        "We provide comprehensive on-site evaluations to assess your security needs and develop customized solutions. Our expert team works closely with you to create tailored security plans that match your specific requirements and budget.",
      imageSrc: require("assets/service/service4.png"),
      imageAlt: "Consultation and Security Planning",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Typography
        variant={isMobile ? "h4" : "h3"}
        component="h2"
        align="center"
        color={theme.palette.secondary.main}
        gutterBottom
      >
        Our Services
      </Typography>

      <Grid container spacing={0} sx={{ mt: 4 }}>
        {services.map((service, index) => (
          <Grid item xs={12} key={index} sx={{ mb: isMobile ? 4 : 0 }}>
            <ServiceItem {...service} index={index} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomeServices;
