import { MenuOpen } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "Core/AppBar/Components/Logo";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer, closeDrawer } from "Services/Redux/settingsSlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState, useEffect, useMemo } from "react";

const UserDrawer = ({ window }) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { isDrawerOpen } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [openSubMenu, setOpenSubMenu] = useState({});
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const navItems = useMemo(
    () => [
      { name: "Home", path: "/" },
      {
        name: "Services",
        children: [
          { name: "Camera Installation", path: "/camera-installation" },
          { name: "Monitoring Solutions", path: "/monitoring-solutions" },
          { name: "Maintenance & Support", path: "/maintenance-support" },
          {
            name: "Consultation & Security Planning",
            path: "/consultation-security-planning",
          },
        ],
      },
      { name: "Products", path: "/products" },
      { name: "Career", path: "/career" },
      {
        name: "About",
        children: [
          { name: "About Us", path: "/about-us" },
          { name: "Our Team", path: "/our-team" },
          { name: "Our Mission", path: "/about/mission" },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    const activeParent = navItems.find(
      (item) =>
        item.path === location.pathname ||
        (item.children &&
          item.children.some((child) => child.path === location.pathname))
    );
    if (activeParent && activeParent.children) {
      setOpenSubMenu((prev) => ({ ...prev, [activeParent.name]: true }));
    }
  }, [location, navItems]);

  const handleClick = (name, path) => {
    if (path) {
      navigate(path);
      dispatch(closeDrawer());
    } else {
      setOpenSubMenu((prevState) => ({
        ...prevState,
        [name]: !prevState[name],
      }));
    }
  };

  const isActive = (path) => location.pathname === path;

  const renderNavItems = (items, level = 0) => (
    <List>
      {items.map((item) => {
        const isItemActive = isActive(item.path);
        const isParentActive =
          item.children && item.children.some((child) => isActive(child.path));
        return (
          <div key={item.name}>
            <ListItem
              button
              onClick={() => handleClick(item.name, item.path)}
              sx={{
                pl: 2 * level,
                color:
                  isItemActive || isParentActive
                    ? theme.palette.secondary.main
                    : "inherit",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{
                  color:
                    isItemActive || isParentActive
                      ? theme.palette.secondary.main
                      : "inherit",
                }}
              />
              {item.children &&
                (openSubMenu[item.name] ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {item.children && (
              <Collapse
                in={openSubMenu[item.name]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {renderNavItems(item.children, level + 1)}
                </List>
              </Collapse>
            )}
          </div>
        );
      })}
    </List>
  );

  return (
    <Box display="flex" sx={{ flexGrow: 1 }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => dispatch(toggleDrawer())}
        sx={{ mr: "auto" }}
      >
        <MenuOpen />
      </IconButton>
      <Logo />
      <Drawer
        anchor="left"
        container={container}
        variant="temporary"
        open={isDrawerOpen}
        onClose={() => dispatch(closeDrawer())}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 260,
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "text.disabled",
              borderRadius: 16,
            },
          },
        }}
      >
        <Stack sx={{ p: 2 }} spacing={1}>
          {renderNavItems(navItems)}
          <Divider />
          <Button
            onClick={() => {
              navigate("/contact-us");
              dispatch(closeDrawer());
            }}
            variant="contained"
            autoCapitalize="false"
            endIcon={<ArrowForwardIcon />}
            sx={{
              backgroundColor: "secondary.main",
              color: "white",
              borderRadius: 0,
              "&:hover": {
                backgroundColor: "secondary.dark",
              },
            }}
          >
            Contact us
          </Button>
        </Stack>
      </Drawer>
    </Box>
  );
};

export default UserDrawer;
