import { Box } from '@mui/material'
import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

const Loading = ({ open }) => {

    return (
        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <RotatingLines width="100" strokeWidth="2" />
        </Box>
    )
}

export default Loading