import { axiosInstance, axiosSecretInstance } from "Services/AxiosService";

export const LOGIN = async (user) =>
  axiosSecretInstance.post(`/auth/login`, JSON.stringify(user));

export const LOG_OUT = async () => axiosSecretInstance.get(`/auth/logout`);

export const REFRESH_TOKEN = async () =>
  axiosSecretInstance.get(`/auth/refresh`);

export const FORGOT_PASSWORD = async (values) =>
  axiosInstance.post(`/auth/forgot-password`, JSON.stringify(values));

export const RESET_PASSWORD = async (values) =>
  axiosInstance.post(`/auth/reset-password`, JSON.stringify(values));
