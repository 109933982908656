import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FooterNavItem from "Components/FooterNavItem";
import {
  ADDRESS,
  SUPPORT_NUMBER,
  SUPPORT_EMAIL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  INSTAGRAM_URL,
} from "const";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        padding: isMobile ? "25px 0px" : "0 46px",
        bgcolor: "background.paper",
        pb: 2,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={isMobile ? 1 : 4}
          sx={{ display: "flex", justifyContent: "space-evenly" }}
        >
          {/* Address Column */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" color="secondary" gutterBottom>
              Address
            </Typography>
            <Stack spacing={2}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <LocationOnIcon color="secondary" />
                <Typography>{ADDRESS}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PhoneIcon color="secondary" />
                <Typography>{SUPPORT_NUMBER}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <EmailIcon color="secondary" />
                <Typography>{SUPPORT_EMAIL}</Typography>
              </Box>
              <Stack
                direction="row"
                mt={2}
                justifyContent={{ xs: "space-evenly", sm: "start" }}
                spacing={{ xs: 0, sm: 1 }}
              >
                <IconButton
                  color="secondary"
                  href={FACEBOOK_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  href={TWITTER_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  href={LINKEDIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  href={INSTAGRAM_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
          {/* Sectors Column */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" color="secondary" gutterBottom>
              Sectors
            </Typography>
            <Stack spacing={1}>
              <FooterNavItem path="/personal" name="Personal Security" />
              <FooterNavItem path="/commercial" name="Commercial Security" />
              <FooterNavItem path="/property" name="Property Security" />
            </Stack>
          </Grid>
          {/* Services Column */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" color="secondary" gutterBottom>
              Services
            </Typography>

            <Stack spacing={1}>
              <FooterNavItem
                path="/camera-installation"
                name="Camera Installation"
              />
              <FooterNavItem
                path="/monitoring-solutions"
                name="Monitoring Solutions"
              />
              <FooterNavItem
                path="/maintenance-support"
                name="Maintenance & Support"
              />
              <FooterNavItem
                path="/consultation-security-planning"
                name="Consultation & Security Planning"
              />
            </Stack>
          </Grid>

          {/* Quick Links Column */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" color="secondary" gutterBottom>
              Quick Links
            </Typography>
            <Stack spacing={1}>
              <FooterNavItem path="/about-us" name="About Us" />
              <FooterNavItem path="/contact-us" name="Contact Us" />
              <FooterNavItem path="/career" name="Career" />
              <FooterNavItem path="/our-team" name="Our Team" />
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ my: isMobile ? 1 : 2 }} />
      </Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: isMobile ? "0 0px" : "0 46px",
        }}
      >
        <Typography variant="body2">
          © Ulka Digital Security, All Right Reserved.
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body2">Designed By Md Mahadi Hassan</Typography>
          <IconButton
            size="small"
            component="a"
            href="https://www.linkedin.com/in/31mahadi"
            target="_blank"
            sx={{ p: 0 }}
          >
            <LinkedInIcon fontSize="small" color="secondary" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
