import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import SecurityIcon from "@mui/icons-material/Security";
import IntegrityIcon from "@mui/icons-material/Verified";
import InnovationIcon from "@mui/icons-material/Lightbulb";
import SupportIcon from "@mui/icons-material/Support";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          height: { xs: "50vh", sm: "50vh", md: "60vh" },
          width: "100%",
          position: "relative",
          backgroundSize: "cover",
          backgroundPosition: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(rgba(255,0,0,0.3), rgba(255,255,255,0.7))`,
          },
        }}
      >
        <Container maxWidth="lg" sx={{ height: "100%", position: "relative" }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: "white",
            }}
          >
            <motion.div {...fadeIn}>
              <Typography
                variant={isMobile ? "h4" : "h2"}
                component="h1"
                gutterBottom
                sx={{ color: "secondary.main", fontWeight: "bold" }}
              >
                Protecting What Matters Most
              </Typography>
              <Typography
                variant={isMobile ? "body1" : "h5"}
                sx={{ mb: 4, color: "secondary.main" }}
              >
                Tailored Security Solutions for Surrey & Langley BC.
              </Typography>
              <Button
                variant="contained"
                size={isMobile ? "medium" : "large"}
                component={Link}
                to="/contact-us"
                sx={{
                  bgcolor: "secondary.main",
                  "&:hover": { bgcolor: "secondary.dark" },
                }}
              >
                Get a Free Consultation
              </Button>
            </motion.div>
          </Box>
        </Container>
      </Box>

      {/* Company Overview */}
      <Container maxWidth="lg" sx={{ my: { xs: 4, md: 8 } }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={require("assets/team.jpg")}
              alt="UDS Team"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div {...fadeIn}>
              <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
                About UDS
              </Typography>
              <Typography paragraph>
                Ulka Digital Security Ltd (UDS) specializes in providing
                state-of-the-art security solutions for residential, commercial,
                and public spaces. With a passion for safety and a commitment to
                customer satisfaction, we have become a trusted partner for
                clients across Surrey and Langley BC.
              </Typography>
              <Typography>
                Our advanced camera systems and tailored monitoring services are
                designed to meet the unique security needs of every client,
                offering peace of mind, 24/7.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Container>

      {/* Mission & Values */}
      <Box sx={{ bgcolor: "background.paper", py: { xs: 4, md: 8 } }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
                    Our Mission
                  </Typography>
                  <Typography>
                    To provide innovative and reliable security solutions that
                    protect lives, property, and peace of mind.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
                    Our Vision
                  </Typography>
                  <Typography>
                    To be the leading security service provider in Surrey and
                    Langley BC, known for exceptional quality, trust, and
                    innovation.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Core Values */}
          <Grid container spacing={3} sx={{ mt: 4 }}>
            {[
              {
                icon: <SecurityIcon />,
                title: "Customer-Centricity",
                desc: "Delivering personalized and effective solutions",
              },
              {
                icon: <IntegrityIcon />,
                title: "Integrity",
                desc: "Building trust through honesty and transparency",
              },
              {
                icon: <InnovationIcon />,
                title: "Innovation",
                desc: "Leveraging cutting-edge technology with precision",
              },
              {
                icon: <SupportIcon />,
                title: "Commitment",
                desc: "Providing 24/7 support and system upgrades",
              },
            ].map((value, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Paper
                    elevation={2}
                    sx={{ p: 2, height: "100%", textAlign: "center" }}
                  >
                    <IconButton color="secondary" sx={{ mb: 1 }}>
                      {value.icon}
                    </IconButton>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h6"}
                      gutterBottom
                    >
                      {value.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {value.desc}
                    </Typography>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box
        sx={{ bgcolor: "secondary.main", color: "white", py: { xs: 4, md: 8 } }}
      >
        <Container maxWidth="md">
          <Stack spacing={3} alignItems="center" textAlign="center">
            <Typography variant={isMobile ? "h5" : "h4"}>
              Ready to enhance your security?
            </Typography>
            <Typography variant={isMobile ? "subtitle1" : "h6"}>
              Let us help protect what matters most to you.
            </Typography>
            <Button
              variant="contained"
              size={isMobile ? "medium" : "large"}
              component={Link}
              to="/contact-us"
              sx={{
                bgcolor: "white",
                color: "secondary.main",
                "&:hover": {
                  bgcolor: "grey.100",
                },
              }}
            >
              Contact Us Today
            </Button>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs;
