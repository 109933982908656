import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  MenuItem,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import CareerModal from "./Components/CareerModal";
import JobCard from "./Components/JobCard";

const Career = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedJob, setSelectedJob] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState("all");
  const [isModalClosing, setIsModalClosing] = useState(false);

  const jobs = [];

  const jobDepartments = ["Operations", "Training", "Technical"];

  const handleOpenModal = (job) => {
    setSelectedJob(job);
    setOpenModal(true);
    setIsModalClosing(false);
  };

  const handleCloseModal = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setOpenModal(false);
      setIsModalClosing(false);
      setSelectedJob(null);
    }, 0);
  };

  const filteredJobs =
    filter === "all" ? jobs : jobs.filter((job) => job.department === filter);

  const pageVariants = {
    initial: {
      opacity: 0,
      rotateY: -90,
      transformOrigin: "left center",
    },
    in: {
      opacity: 1,
      rotateY: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    out: {
      opacity: 0,
      rotateY: 90,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        py: { xs: 3, md: 6 },
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant={isMobile ? "h5" : "h3"}
            component="h1"
            align="center"
            gutterBottom
            sx={{
              mb: { xs: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
              textTransform: "uppercase",
            }}
          >
            Join Our Team
          </Typography>

          <Typography
            variant={isMobile ? "body1" : "h6"}
            align="center"
            sx={{
              mb: 4,
              maxWidth: "800px",
              mx: "auto",
              color: theme.palette.text.primary,
              px: isMobile ? 2 : 0,
            }}
          >
            Build your career with a leading security solutions provider. We
            offer competitive benefits, professional development, and
            opportunities for growth in the security industry.
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
            <TextField
              select
              label="Filter by Department"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              sx={{ minWidth: isMobile ? "100%" : 200 }}
              color="secondary"
            >
              <MenuItem value="all">All Departments</MenuItem>
              {jobDepartments?.map((department) => (
                <MenuItem key={department} value={department}>
                  {department}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </motion.div>

        <Grid container spacing={3}>
          {filteredJobs.length > 0 ? (
            filteredJobs.map((job, index) => (
              <Grid item xs={12} sm={6} md={6} key={job.id}>
                <JobCard
                  job={job}
                  index={index}
                  isMobile={isMobile}
                  theme={theme}
                  onOpenModal={handleOpenModal}
                  hoverColor={theme.palette.secondary.main}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="textSecondary">
                There are currently no active job openings available. Please
                check back later for new opportunities.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>

      <AnimatePresence mode="wait">
        {(openModal || isModalClosing) && (
          <motion.div
            key="modal"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
          >
            <CareerModal
              openModal={openModal}
              handleCloseModal={handleCloseModal}
              selectedJob={selectedJob}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Career;
