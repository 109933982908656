import {
  Box,
  Stack,
  Avatar,
  Button,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Logo from "Core/AppBar/Components/Logo";
import NavItem from "Core/AppBar/Components/NavItem";
import { useSelector } from "react-redux";
import useAuth from "Services/Hooks/useAuth";
import { AccountCircle } from "@mui/icons-material";
import MenuProvider from "Components/MenuProvider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AdminUserOptions from "Core/AppBar/UserOptions/AdminUserOptions";

const AdminAppbar = () => {
  const [anchorUserEl, setAnchorUserEl] = useState(null);
  const isAuth = useAuth();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleUserMenuOpen = (event) => {
    setAnchorUserEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorUserEl(null);
  };

  const navItems = [{ name: "Home", path: "/admin" }];

  return (
    <Box
      display="flex"
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Logo />

      <Stack direction="row">
        {navItems.map((item) => (
          <NavItem
            key={item.name}
            name={item.name}
            path={item.path}
            children={item.children}
          />
        ))}
      </Stack>

      <Box display="flex" sx={{ marginLeft: "auto", alignItems: "center" }}>
        {auth?.isLoading ? (
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        ) : isAuth ? (
          <Tooltip title="Menus">
            <IconButton
              onClick={(event) => handleUserMenuOpen(event)}
              sx={{ p: 0.3 }}
            >
              <AccountCircle color="secondary" fontSize="large" />
            </IconButton>
          </Tooltip>
        ) : (
          <Button onClick={() => navigate("/login")} variant="outlined">
            Login
          </Button>
        )}
        {isAuth && (
          <MenuProvider
            anchorEl={anchorUserEl}
            handleClose={handleUserMenuClose}
          >
            <AdminUserOptions />
          </MenuProvider>
        )}
      </Box>
    </Box>
  );
};

export default AdminAppbar;
