import React from "react";
import { Grid, Container, Box } from "@mui/material";
import { ContactUsForm } from "./Components/ContactUsForm";
import GoogleMap from "./Components/GoogleMap";
import { motion } from "framer-motion";

const ContactUs = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <Box sx={{ py: { xs: 3, md: 2 } }}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid item md={6} xs={12} sx={{ display: "flex", height: "100%" }}>
            <motion.div {...fadeIn} style={{ width: "100%", height: "100%" }}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <ContactUsForm />
              </Box>
            </motion.div>
          </Grid>

          <Grid item md={6} xs={12} sx={{ display: "flex", height: "100%" }}>
            <motion.div
              {...fadeIn}
              transition={{ delay: 0.2, duration: 0.6 }}
              style={{ width: "100%", height: "100%" }}
            >
              <Box sx={{ width: "100%", height: "100%" }}>
                <GoogleMap />
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUs;
