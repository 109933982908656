import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material'
import { useState } from 'react'
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import PhoneInput from 'react-phone-input-2'
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateFnsUtils from "@date-io/date-fns"
import { dfReadableToServerYM } from 'Helpers/DateFormat'
import { useSelector } from "react-redux"


// Linked from user profile, admin user update, signup page
const NewUserForm = ({ isLoading, handleSubmit }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const bdPhoneRegExp = /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/
    const fullNameRegExp = /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/;

    const { isEnglish } = useSelector((state) => state.settings)

    const INITIAL_FORM_STATE = {
        full_name: '',
        mobile: '',
        email: '',
        password: '',
        confirm_password: '',
        date_of_birth: '',
        address: '',
        gender: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        full_name: Yup.string()
            .required('Required')
            .nullable()
            .matches(fullNameRegExp, 'Name not valid'),
        mobile: Yup.string()
            .required('Required')
            .nullable()
            .matches(bdPhoneRegExp, 'Phone number is not valid'),
        email: Yup.string()
            .required('Required')
            .email('Invalid email address')
            .nullable(),
        password: Yup.string()
            .required('Required')
            .nullable(),
        confirm_password: Yup.string()
            .required('Required')
            .nullable()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        date_of_birth: Yup.date()
            .typeError('Invalid Date')
            .required('Required')
            .nullable(),
        address: Yup.string()
            .required('Required')
            .nullable(),
        gender: Yup.string()
            .required('Required')
            .nullable(),
    })

    return (
        <Formik
            initialValues={{ ...INITIAL_FORM_STATE }}
            validationSchema={FORM_VALIDATION}
            onSubmit={values => handleSubmit(values)}>
            {({ setFieldValue, values, touched, errors, setTouched, submitForm }) => (
                <Form noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                name='full_name'
                                label={isEnglish ? 'Full Name' : 'সম্পূর্ণ নাম'}
                                placeholder={isEnglish ? 'Full Name' : ' সম্পূর্ণ নাম'}
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.user}
                                onChange={(e) => setFieldValue('full_name', e.target.value?.trim() ? e.target.value?.trim() : null)}
                                onBlur={() => setTouched({ ...touched, full_name: true })}
                                error={touched.full_name && errors.full_name}
                                helperText={touched.full_name && errors.full_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <PhoneInput
                                    specialLabel={isEnglish ? 'Mobile *' : 'মোবাইল *'}
                                    country={'bd'}
                                    onlyCountries={['bd']}
                                    placeholder={isEnglish ? 'Mobile' : 'মোবাইল'}
                                    value={values.mobile}
                                    onChange={phone => setFieldValue('mobile', phone ? phone : null)}
                                    onBlur={() => setTouched({ ...touched, mobile: true })}
                                    inputStyle={{ width: '100%' }}
                                    isValid={() => {
                                        if (touched.mobile && errors.mobile)
                                            return 'Mobile *'
                                        return true
                                    }}
                                />
                                {(touched.mobile && errors.mobile) && <FormHelperText error style={{ paddingLeft: 16 }}>{errors.mobile}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='email'
                                label={isEnglish ? 'Email' : 'ইমেইল'}
                                placeholder={isEnglish ? 'Email' : 'ইমেইল'}
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.email}
                                onChange={(e) => setFieldValue('email', e.target.value ? e.target.value : null)}
                                onBlur={() => setTouched({ ...touched, email: true })}
                                error={touched.email && errors.email}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="password"
                                label={isEnglish ? 'Password' : 'পাসওয়ার্ড'}
                                placeholder={isEnglish ? 'Password' : 'পাসওয়ার্ড'}
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.password}
                                onChange={(e) => setFieldValue('password', e.target.value ? e.target.value : null)}
                                onBlur={() => setTouched({ ...touched, password: true })}
                                error={touched.password && errors.password}
                                helperText={touched.password && errors.password}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="confirm_password"
                                label={isEnglish ? 'Password' : 'নিশ্চিত করুন পাসওয়ার্ড'}
                                placeholder={isEnglish ? 'Password' : 'নিশ্চিত করুন পাসওয়ার্ড'}
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.confirm_password}
                                onChange={(e) => setFieldValue('confirm_password', e.target.value ? e.target.value : null)}
                                onBlur={() => setTouched({ ...touched, confirm_password: true })}
                                error={touched.confirm_password && errors.confirm_password}
                                helperText={touched.confirm_password && errors.confirm_password}
                                type={showPassword2 ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword2(!showPassword2)}
                                            >
                                                {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label={isEnglish ? 'Birth date *' : 'জন্ম তারিখ *'}
                                    value={values.date_of_birth}
                                    onChange={(date) => setFieldValue('date_of_birth', date ? dfReadableToServerYM(date) : '')}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        fullWidth
                                        error={touched.date_of_birth && errors.date_of_birth}
                                        helperText={touched.date_of_birth && errors.date_of_birth}

                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='address'
                                label={isEnglish ? 'Address' : 'ঠিকানা'}
                                placeholder={isEnglish ? 'Address' : 'ঠিকানা'}
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.address}
                                onChange={(e) => setFieldValue('address', e.target.value ? e.target.value : null)}
                                onBlur={() => setTouched({ ...touched, address: true })}
                                error={touched.address && errors.address}
                                helperText={touched.address && errors.address}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={touched.gender && errors.gender}>
                                <FormLabel>{isEnglish ? 'Gender *' : 'লিঙ্গ *'}</FormLabel>
                                <RadioGroup
                                    row
                                    defaultValue="male"
                                    value={values.gender}
                                    onChange={(e) => setFieldValue('gender', e?.target?.value)}
                                >
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} textAlign='end'>
                            <LoadingButton
                                loading={isLoading}
                                loadingPosition="end"
                                variant='contained'
                                size='large'
                                sx={{ color: '#fff', ml: 'auto' }}
                                endIcon={<ArrowForward />}
                                onClick={() => submitForm()}
                            >{isEnglish ? 'Submit' : 'জমা দিন'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            )
            }
        </Formik >
    )
}

export default NewUserForm