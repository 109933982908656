import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { createBlacklistFilter } from "redux-persist-transform-filter";

import authReducer from "./authSlice";
import settingsReducer from "./settingsSlice";

const reducers = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["settings"],
  transforms: [createBlacklistFilter("settings", ["isDrawerOpen"])],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV !== "PRODUCTION",
  middleware: [thunk],
});

export default store;
