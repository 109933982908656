import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
import { motion } from "framer-motion";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";

const SocialIcon = ({ icon: Icon, href, theme }) => {
  if (!href) return null;
  return (
    <IconButton
      component={motion.button}
      whileHover={{ color: theme.palette.secondary.main }}
      transition={{ duration: 0.5 }}
      size="small"
      sx={{
        color: "white",
        mb: 1,
      }}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon />
    </IconButton>
  );
};

const TeamMemberCard = ({ member, index, isMobile, theme }) => {
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  return (
    <Grid item xs={12} sm={6} md={3} key={index}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
      >
        <Card
          elevation={3}
          sx={{
            width: isMobile ? "90%" : "100%",
            margin: "auto",
            position: "relative",
            overflow: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            "&:hover .social-icons": {
              opacity: 1,
              transform: "translateX(0)",
            },
          }}
        >
          <Box
            sx={{
              height: { xs: 360, sm: 280 },
              position: "relative",
              overflow: "hidden",
            }}
          >
            <CardMedia
              component={motion.img}
              image={member.image || ""}
              alt={""}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.3 }}
              sx={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                display: member.image ? "block" : "none",
              }}
            />
            <Avatar
              component={motion.div}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.3 }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 80, sm: 120 },
                height: { xs: 80, sm: 120 },
                fontSize: { xs: "2rem", sm: "3rem" },
                bgcolor: theme.palette.secondary.main,
                display: "none",
              }}
            >
              {getInitials(member.name)}
            </Avatar>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "8px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "white",
                textAlign: "center",
              }}
            >
              {member.name}
            </Typography>
            <Box
              className="social-icons"
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                opacity: 0,
                transform: "translateX(-100%)",
                transition: "all 0.3s ease-in-out",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: "8px",
              }}
            >
              <SocialIcon
                icon={FacebookIcon}
                href={member.facebook}
                theme={theme}
              />
              <SocialIcon
                icon={TwitterIcon}
                href={member.twitter}
                theme={theme}
              />
              <SocialIcon
                icon={LinkedInIcon}
                href={member.linkedin}
                theme={theme}
              />
              <SocialIcon
                icon={InstagramIcon}
                href={member.instagram}
                theme={theme}
              />
              <SocialIcon
                icon={EmailIcon}
                href={`mailto:${member.email}`}
                theme={theme}
              />
            </Box>
          </Box>
          <CardContent
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Tooltip title={member.role} placement="top">
              <Typography
                variant={isMobile ? "subtitle2" : "h6"}
                color="secondary"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
              >
                {member.role}
              </Typography>
            </Tooltip>
            <Tooltip title={member.description} placement="top">
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  cursor: "pointer",
                  height: "2.5em",
                  lineHeight: "1.25em",
                }}
              >
                {member.description}
              </Typography>
            </Tooltip>
          </CardContent>
        </Card>
      </motion.div>
    </Grid>
  );
};

const OurTeam = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const teamMembers = [
    {
      name: "Mohammed Ariful ISLAM",
      role: "CEO & Founder",
      image: require("assets/team/ceo.jpg"),
      description:
        "20+ years of experience in security systems and management.",
      alt: "Mohammed Ariful ISLAM",
      facebook: "https://facebook.com/arifulislam",
      email: "arifulislam@gmail.com",
    },
  ];

  return (
    <Box sx={{ py: { xs: 4, md: 8 } }}>
      <Container maxWidth="lg">
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          align="center"
          gutterBottom
          sx={{ mb: { xs: 4, md: 6 }, color: "secondary.main" }}
        >
          Meet Our Team
        </Typography>

        <Grid
          container
          spacing={isMobile ? 2 : 4}
          justifyContent={teamMembers.length < 4 ? "center" : "flex-start"}
        >
          {teamMembers.map((member, index) => (
            <TeamMemberCard
              key={index}
              member={member}
              index={index}
              isMobile={isMobile}
              theme={theme}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurTeam;
