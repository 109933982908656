import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    persisted: false,
    isDrawerOpen: false,
    toolbarHeight: 0,
  },
  reducers: {
    setPersisted: (state, action) => {
      state.persisted = action.payload;
    },
    closeDrawer: (state, action) => {
      state.isDrawerOpen = false;
    },
    toggleDrawer: (state, action) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setToolbarHeight: (state, action) => {
      state.toolbarHeight = action.payload;
    },
  },
});

export const { setPersisted, closeDrawer, toggleDrawer, setToolbarHeight } =
  settingsSlice.actions;

export default settingsSlice.reducer;
