import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { ArrowForward } from '@mui/icons-material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import PhoneInput from 'react-phone-input-2'
import { DatePicker, LocalizationProvider } from "@mui/lab"
import DateFnsUtils from "@date-io/date-fns"
import { dfReadableToServerYM } from 'Helpers/DateFormat'


const UserUpdateForm = ({ user, handleSubmit, isLoading }) => {
    const bdPhoneRegExp = /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/
    const fullNameRegExp = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
    const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({})

    useEffect(() => {
        setINITIAL_FORM_STATE(user)
    }, [user])

    const FORM_VALIDATION = Yup.object().shape({
        full_name: Yup.string()
            .required('Required')
            .nullable()
            .matches(fullNameRegExp, 'Name not valid'),
        mobile: Yup.string()
            .required('Required')
            .nullable()
            .matches(bdPhoneRegExp, 'Phone number is not valid'),
        email: Yup.string()
            .required('Required')
            .email('Invalid email address')
            .nullable(),
        address: Yup.string()
            .required('Required')
            .nullable(),
        date_of_birth: Yup.date()
            .typeError('Invalid Date')
            .required('Required')
            .nullable(),
        gender: Yup.string()
            .required('Required')
            .nullable(),
    })

    return (
        <Formik
            initialValues={{ ...INITIAL_FORM_STATE }}
            validationSchema={FORM_VALIDATION}
            enableReinitialize={true}
            onSubmit={values => handleSubmit(values)}>
            {({ setFieldValue, values, touched, errors, setTouched, submitForm }) => (
                <Form noValidate >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                name='full_name'
                                label='Full Name'
                                placeholder='Full Name'
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.full_name || ''}
                                onChange={(e) => setFieldValue('full_name', e.target.value ? e.target.value : null)}
                                onBlur={() => setTouched({ ...touched, full_name: true })}
                                error={touched.full_name && errors.full_name}
                                helperText={touched.full_name && errors.full_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='email'
                                label='Email'
                                placeholder='Email'
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.email || ''}
                                onChange={(e) => setFieldValue('email', e.target.value ? e.target.value : null)}
                                onBlur={() => setTouched({ ...touched, email: true })}
                                error={touched.email && errors.email}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <PhoneInput
                                    specialLabel='Mobile *'
                                    country={'bd'}
                                    onlyCountries={['bd']}
                                    placeholder='Mobile'
                                    value={values.mobile}
                                    onChange={phone => setFieldValue('mobile', phone ? phone : null)}
                                    onBlur={() => setTouched({ ...touched, mobile: true })}
                                    inputStyle={{ width: '100%' }}
                                    isValid={() => {
                                        if (touched.mobile && errors.mobile)
                                            return 'Mobile *'
                                        return true
                                    }}
                                />
                                {(touched.mobile && errors.mobile) && <FormHelperText error style={{ paddingLeft: 16 }}>{errors.mobile}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='address'
                                label='Address'
                                placeholder='Address'
                                required
                                fullWidth={true}
                                variant='outlined'
                                value={values.address || ''}
                                onChange={(e) => setFieldValue('address', e.target.value ? e.target.value : null)}
                                onBlur={() => setTouched({ ...touched, address: true })}
                                error={touched.address && errors.address}
                                helperText={touched.address && errors.address}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label={'Birth date *'}
                                    value={values.date_of_birth}
                                    onChange={(date) => setFieldValue('date_of_birth', date ? dfReadableToServerYM(date) : '')}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        fullWidth
                                        error={touched.date_of_birth && errors.date_of_birth}
                                        helperText={touched.date_of_birth && errors.date_of_birth}

                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={touched.gender && errors.gender}>
                                <FormLabel>Gender</FormLabel>
                                <RadioGroup
                                    row
                                    defaultValue="male"
                                    value={values.gender || ''}
                                    onChange={(e) => setFieldValue('gender', e?.target?.value)}
                                >
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} textAlign='end'>
                            <LoadingButton
                                loading={isLoading}
                                loadingPosition="end"
                                variant='contained'
                                size='large'
                                sx={{ color: '#fff', ml: 'auto' }}
                                endIcon={<ArrowForward />}
                                onClick={() => submitForm()}
                            >Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default UserUpdateForm