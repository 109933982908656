import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { MessageDialog } from "Core/Dialogs/SwalDialogs";
import { RESET_PASSWORD } from "Api/Auth";

const ResetPassword = () => {
  const { state } = useLocation();
  const uid = state?.uid;
  const email = state?.email;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const INITIAL_FORM_STATE = {
    verification_code: "",
    password: "",
    confirm_password: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    verification_code: Yup.string().required("Required"),
    password: Yup.string().required("Required").nullable(),
    confirm_password: Yup.string()
      .required("Required")
      .nullable()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const result = await RESET_PASSWORD({ ...values, uid });
      if (result.status === 201) {
        MessageDialog(
          result.data.title,
          result.data.message,
          result.data.status
        );
        navigate("/login", { replace: true });
      }
    } catch (error) {
      MessageDialog(
        error.response.data.title,
        error.response.data.message,
        error.response.data.status
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card elevation={2} sx={{ p: 6, width: "100%" }}>
        <Stack spacing={2}>
          <Typography fontWeight="bold" variant="h5">
            Password change
          </Typography>
          <Divider />
          <Typography variant="body1">
            Please enter the verification code sent to your email address{" "}
            <strong>{email}</strong>
          </Typography>
          <Formik
            initialValues={{ ...INITIAL_FORM_STATE }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              setFieldValue,
              values,
              touched,
              errors,
              setTouched,
              submitForm,
            }) => (
              <Form noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      name="verification_code"
                      placeholder="VERIFICATION CODE"
                      required
                      fullWidth={true}
                      variant="outlined"
                      value={values.verification_code}
                      onChange={(e) =>
                        setFieldValue(
                          "verification_code",
                          e.target.value ? e.target.value : null
                        )
                      }
                      error={
                        touched.verification_code && errors.verification_code
                      }
                      helperText={
                        touched.verification_code && errors.verification_code
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      label="Password"
                      placeholder="Password"
                      required
                      fullWidth={true}
                      variant="outlined"
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue(
                          "password",
                          e.target.value ? e.target.value : null
                        )
                      }
                      error={touched.password && errors.password}
                      helperText={touched.password && errors.password}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="confirm_password"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      required
                      fullWidth={true}
                      variant="outlined"
                      value={values.confirm_password}
                      onChange={(e) =>
                        setFieldValue(
                          "confirm_password",
                          e.target.value ? e.target.value : null
                        )
                      }
                      error={
                        touched.confirm_password && errors.confirm_password
                      }
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      type={showPassword2 ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword2(!showPassword2)}
                            >
                              {showPassword2 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="end">
                    <LoadingButton
                      loading={isLoading}
                      loadingPosition="end"
                      variant="contained"
                      size="large"
                      sx={{ color: "#fff", ml: "auto" }}
                      endIcon={<ArrowForward />}
                      onClick={() => submitForm()}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Stack>
      </Card>
    </Container>
  );
};

export default ResetPassword;
