import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { motion } from "framer-motion";

const MaintenanceAndSupport = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoading, setImageLoading] = useState({});

  const sections = [
    {
      title: "Regular System Maintenance",
      description:
        "Our comprehensive maintenance program includes scheduled system checks, hardware inspections, and software updates to ensure optimal performance. We proactively identify and address potential issues before they impact your security system's functionality. Our expert technicians perform thorough diagnostics and necessary adjustments to maintain peak operational efficiency.",
      imageAlt: "System Maintenance",
      imageSrc: require("assets/service/system-maintenance.png"),
      imageAlternative:
        "A technician performing maintenance checks on security system components",
    },
    {
      title: "24/7 Technical Support",
      description:
        "Access round-the-clock technical assistance from our dedicated support team whenever you need help. Our experienced professionals are always ready to troubleshoot issues, provide guidance, and offer remote assistance to resolve technical concerns promptly. We prioritize minimal system downtime and quick resolution to maintain continuous security coverage.",
      imageAlt: "Technical Support",
      imageSrc: require("assets/service/technical-support.png"),
      imageAlternative:
        "Support team member assisting a client through remote technical support",
    },
    {
      title: "System Updates and Upgrades",
      description:
        "Stay ahead with the latest security features through our regular system updates and upgrade services. We implement security patches, firmware updates, and system enhancements to protect against emerging threats. Our team also provides recommendations for hardware upgrades when newer, more effective technologies become available to improve your security infrastructure.",
      imageAlt: "System Updates",
      imageSrc: require("assets/service/system-updates.png"),
      imageAlternative:
        "Visual representation of system software being updated with new security features",
    },
  ];

  const handleImageLoad = (index) => {
    setImageLoading((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
          sx={{ mb: 6 }}
        >
          Maintenance and Support Services
        </Typography>
      </motion.div>

      <Grid container spacing={6}>
        {sections.map((section, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Grid
                container
                spacing={4}
                alignItems="center"
                direction={index % 2 === 0 ? "row" : "row-reverse"}
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    color={theme.palette.secondary.main}
                    gutterBottom
                  >
                    {section.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {section.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ position: "relative" }}>
                    {(imageLoading[index] === undefined ||
                      imageLoading[index]) && (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={isMobile ? 300 : 400}
                        animation="wave"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1,
                          borderRadius: 2,
                        }}
                      />
                    )}
                    <Box
                      component="img"
                      src={section.imageSrc}
                      alt={section.imageAlt}
                      title={section.imageAlternative}
                      onLoad={() => handleImageLoad(index)}
                      sx={{
                        width: "100%",
                        height: "auto",
                        borderRadius: 2,
                        boxShadow: 3,
                        maxHeight: isMobile ? "300px" : "400px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MaintenanceAndSupport;
