import React from "react";
import { motion } from "framer-motion";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";

const JobCard = ({ job, index, isMobile, theme, onOpenModal }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: index * 0.1 }}
    >
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          "&:hover": {
            transform: "translateY(-4px)",
            transition: "transform 0.2s ease-in-out",
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              mb: 1,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontWeight: "bold",
                mb: isMobile ? 1 : 0,
              }}
            >
              {job.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Deadline: {job.deadline}
            </Typography>
          </Box>
          <Typography color="textSecondary" gutterBottom>
            {job.department} • {job.location} • {job.type}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {job.description}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => onOpenModal(job)}
            sx={{
              color: theme.palette.secondary.main,
              borderColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
              },
              width: isMobile ? "100%" : "auto",
            }}
          >
            View Details
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default JobCard;
