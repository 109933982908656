import axios from "axios";

const _URL =
  process.env.REACT_APP_ENV === "PRODUCTION"
    ? process.env.REACT_APP_API_PROD
    : process.env.REACT_APP_API_DEV;

export const axiosInstance = axios.create({
  baseURL: _URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const axiosSecretInstance = axios.create({
  baseURL: _URL,
  withCredentials: true, // For sending cookies
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const axiosPrivateInstance = axios.create({
  baseURL: _URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
