import React from "react";
import {
  Stack,
  Typography,
  Container,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HomeCarousel from "./Components/HomeCarousel";
import WhyUs from "./Components/WhyUs";
import ContactUs from "../ContactUs/ContactUs";
import AboutUs from "./Components/AboutUs";
import HomeServices from "./Components/HomeServices";
import Testimonials from "./Components/Testimonials";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const carouselItems = [
    {
      title: "Ulka Digital Security",
      description:
        "Professional Security Solutions for Homes and Businesses or Personal security",
      buttonText: "Learn More",
      buttonLink: "/personal",
    },
    {
      title: "Commercial Security",
      description:
        "Comprehensive Video Surveillance and Access Control for Your Business",
      buttonText: "View Services",
      buttonLink: "/commercial",
    },
    {
      title: "Property Security",
      description:
        "24/7 Monitoring and Advanced Security Systems for Complete Peace of Mind",
      buttonText: "Explore Solutions",
      buttonLink: "/property",
    },
  ];

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Stack spacing={isMobile ? 4 : 8} width="100%">
        {/* Carousel Section */}
        {/* TODO: Add a loading state */}
        <Box>
          <HomeCarousel carouselItems={carouselItems} />
        </Box>

        {/* About Us Section */}
        <Box sx={{ py: isMobile ? 4 : 8, width: "100%" }}>
          <AboutUs />
        </Box>

        {/* Services Section */}
        <Box
          sx={{
            py: isMobile ? 4 : 8,
            bgcolor: "background.paper",
            width: "100%",
          }}
        >
          <HomeServices />
        </Box>

        {/* Why Choose Us Section */}
        <Box sx={{ py: isMobile ? 4 : 8, width: "100%" }}>
          <Container maxWidth="lg">
            <WhyUs />
          </Container>
        </Box>

        {/* Testimonials Section */}
        <Box sx={{ py: isMobile ? 4 : 8, width: "100%" }}>
          <Testimonials />
        </Box>

        {/* Contact Us Section */}
        <Box
          sx={{
            bgcolor: "background.paper",
            py: isMobile ? 4 : 8,
            width: "100%",
          }}
        >
          <Container>
            <Typography
              variant={isMobile ? "h4" : "h3"}
              component="h2"
              align="center"
              gutterBottom
              color={theme.palette.secondary.main}
            >
              Get in Touch
            </Typography>
            <ContactUs />
          </Container>
        </Box>
      </Stack>
    </Box>
  );
};

export default Home;
