export const SUPPORT_EMAIL = "support@ulkadigitalsecurity.com";
export const SUPPORT_NUMBER = "+604 506 4954";
export const SUPPPORT_NUMBER_WHATSAPP = "+1 236 994 9894";
export const ADDRESS =
  "17220 Heather Dr, Unit 123, Surrey, BC, V3S 0B4, Canada";
export const BUSINESS_HOURS = "Mon - Fri : 09.00 AM - 09.00 PM";
export const FACEBOOK_URL = "https://www.facebook.com/ulkadigitalsecurity";
export const LINKEDIN_URL = "https://www.linkedin.com/in/ulkadigitalsecurity";
export const TWITTER_URL = "https://www.twitter.com/ulkadigitalsecurity";
export const INSTAGRAM_URL = "https://www.instagram.com/ulkadigitalsecurity";
