import { useSelector } from "react-redux"

const useAuth = () => {
    const { auth } = useSelector((state) => state.auth)

    const isAuth = auth?.user && auth?.accessToken

    return isAuth
}

export default useAuth