import React from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Icon,
} from "@mui/material";
import { motion } from "framer-motion";
import BusinessIcon from "@mui/icons-material/Business";
import SecurityIcon from "@mui/icons-material/Security";
import VideocamIcon from "@mui/icons-material/Videocam";
import SensorsIcon from "@mui/icons-material/Sensors";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BadgeIcon from "@mui/icons-material/Badge";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WarehouseIcon from "@mui/icons-material/Warehouse";

const CommercialSecurity = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const sections = [
    {
      title: "Business Surveillance Systems",
      description:
        "Protect your business premises with our state-of-the-art surveillance solutions. Our commercial security systems include HD cameras, advanced motion detection, and comprehensive monitoring capabilities. Keep track of all activities in and around your business location with crystal clear video quality and intelligent analytics.",
      icons: [
        BusinessIcon,
        VideocamIcon,
        SensorsIcon,
        SecurityIcon,
        NotificationsActiveIcon,
      ],
    },
    {
      title: "Access Control Solutions",
      description:
        "Manage and monitor access to your commercial property effectively. Our access control systems include key card entry, biometric scanners, and visitor management solutions. Track employee attendance, restrict unauthorized access, and maintain detailed entry logs for enhanced security.",
      icons: [LockIcon, BadgeIcon, AccessTimeIcon],
    },
    {
      title: "Retail & Warehouse Security",
      description:
        "Specialized security solutions for retail stores and warehouses. Prevent theft, monitor inventory, and protect assets with our integrated security systems. Get real-time alerts, inventory tracking capabilities, and comprehensive security coverage for your commercial space.",
      icons: [
        StorefrontIcon,
        WarehouseIcon,
        VideocamIcon,
        NotificationsActiveIcon,
      ],
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant={isMobile ? "h4" : isTablet ? "h3" : "h2"}
          component="h1"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
          sx={{ mb: { xs: 3, sm: 4, md: 6 } }}
        >
          Commercial Security Solutions
        </Typography>
      </motion.div>

      <Grid container spacing={{ xs: 3, sm: 4, md: 6 }}>
        {sections.map((section, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Grid
                container
                spacing={{ xs: 2, sm: 3, md: 4 }}
                alignItems="center"
                direction={
                  isMobile ? "column" : index % 2 === 0 ? "row" : "row-reverse"
                }
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant={isMobile ? "h5" : isTablet ? "h4" : "h3"}
                    color={theme.palette.secondary.main}
                    gutterBottom
                  >
                    {section.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: { xs: 1, sm: 2 } }}>
                    {section.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { xs: 150, sm: 180, md: 200 },
                      backgroundColor: theme.palette.background.default,
                      p: 2,
                    }}
                  >
                    {section.icons.map((IconComponent, iconIndex) => (
                      <Icon
                        key={iconIndex}
                        component={IconComponent}
                        sx={{
                          fontSize: { xs: 40, sm: 50, md: 60 },
                          color: theme.palette.secondary.main,
                          m: { xs: 1, sm: 1.5, md: 2 },
                        }}
                      />
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CommercialSecurity;
