import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DashboardTopSection = ({ subTitle }) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" sx={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.1)" }}>
      <Stack direction="row" p={1} alignItems="end">
        <Box>
          <Typography color="secondary" variant="h4">
            Welcome!
          </Typography>
          <Typography variant="overline" fontSize={14}>
            {subTitle}
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          ml: "auto",
          p: 1,
          width: 250,
          backgroundColor: "secondary.main",
          clipPath: "polygon(0 0, 100% 0, 100% 100% , 30% 100%)",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Button
          variant="text"
          endIcon={<ArrowForward />}
          sx={{ color: "#fff" }}
          onClick={() => {
            navigate("/");
          }}
        >
          User Panel
        </Button>
      </Box>
    </Stack>
  );
};

export default DashboardTopSection;
