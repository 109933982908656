import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { motion } from "framer-motion";

const CameraInstallation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoading, setImageLoading] = useState({});

  const sections = [
    {
      title: "High-Definition Surveillance",
      description:
        "Our state-of-the-art CCTV camera systems deliver unparalleled video quality, leveraging the latest in high-definition technology. Experience a new level of visual clarity that allows you to identify and monitor activities with exceptional precision and detail. Our cameras capture every nuance, ensuring that you never miss a crucial moment in safeguarding your property.",
      imageSrc: require("assets/service/camera-hd.jpg"),
      imageAlt: "HD Camera System",
      imageAlternative:
        "A high-definition security camera mounted on a wall, showcasing its sleek design and advanced lens technology.",
    },
    {
      title: "Smart Remote Access",
      description:
        "Stay seamlessly connected to your security system from anywhere in the world with our advanced remote access capabilities. Our user-friendly mobile app and intuitive web interface empower you to monitor live feeds, review past recordings, and receive instant alerts in real-time. This cutting-edge functionality ensures that you're always in control of your property's security, whether you're at home, at work, or on vacation.",
      imageSrc: require("assets/service/remote-access.png"),
      imageAlt: "Remote Access Interface",
      imageAlternative:
        "A smartphone displaying a user-friendly app interface with live camera feeds and control options for remote security management.",
    },
    {
      title: "Advanced Features",
      description:
        "Elevate your security measures with our cutting-edge night vision technology and intelligent motion detection systems. Our advanced cameras automatically adapt to varying lighting conditions, providing clear, detailed footage even in complete darkness. The smart motion detection feature employs sophisticated algorithms to differentiate between genuine security threats and harmless movements, significantly reducing false alarms while ensuring you're promptly alerted to any suspicious activity.",
      imageSrc: require("assets/service/night-vision.png"),
      imageAlt: "Night Vision Camera",
      imageAlternative:
        "A night vision camera capturing clear footage in low-light conditions, with visible infrared LEDs surrounding the lens.",
    },
  ];

  const handleImageLoad = (index) => {
    setImageLoading((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
          sx={{ mb: 6 }}
        >
          Camera Installation Services
        </Typography>
      </motion.div>

      <Grid container spacing={6}>
        {sections.map((section, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Grid
                container
                spacing={4}
                alignItems="center"
                direction={index % 2 === 0 ? "row" : "row-reverse"}
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    color={theme.palette.secondary.main}
                    gutterBottom
                  >
                    {section.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {section.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ position: "relative" }}>
                    {!imageLoading[index] === false && (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={isMobile ? 300 : 400}
                        animation="wave"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1,
                          borderRadius: 2,
                        }}
                      />
                    )}
                    <Box
                      component="img"
                      src={section.imageSrc}
                      alt={section.imageAlt}
                      title={section.imageAlternative}
                      onLoad={() => handleImageLoad(index)}
                      sx={{
                        width: "100%",
                        height: "auto",
                        borderRadius: 2,
                        boxShadow: 3,
                        maxHeight: isMobile ? "300px" : "400px",
                        objectFit: "cover",
                        visibility: !imageLoading[index] ? "visible" : "hidden",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CameraInstallation;
