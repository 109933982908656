import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { USER_ROLE } from 'Helpers/UserRole'

const ChangeRole = ({ handleClick }) => {
    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Change role</InputLabel>
                <Select
                    label="Change role"
                    onChange={(event) => handleClick({ role: event.target.value })}
                >
                    {
                        Object.keys(USER_ROLE).map((key) => {
                            return (
                                <MenuItem key={key} value={key}>{USER_ROLE[key]}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    )
}

export default ChangeRole