import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserUpdateForm from "Modules/Common/UserUpdateForm";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import BoxTitleContent from "Components/BoxTitleContent";
import { USER_ROLE } from "Helpers/UserRole";
import { UPDATE_USER_INFO, GET_USER, UPDATE_USER_STATUS } from "Api/Admin/User";
import ChangeRole from "Components/User/ChangeRole";
import BlockUser from "Components/User/BlockUser";
import { MessageDialog } from "Core/Dialogs/SwalDialogs";

const UserManage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const user_id = state?.user_id;
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await GET_USER(user_id);
        setUser(result.data.user);
      } catch (error) {
        MessageDialog(
          error.response.data.title,
          error.response.data.message,
          error.response.data.status
        );
      }
    };
    fetchUser();
  }, [user_id]);

  const updateUserInformation = async (values) => {
    try {
      setIsLoading(true);
      const result = await UPDATE_USER_INFO(user.id, values);
      if (result.status === 201) {
        setUser((state) => ({
          ...state,
          ...values,
        }));
        MessageDialog(
          result.data.title,
          result.data.message,
          result.data.status
        );
      }
    } catch (error) {
      MessageDialog(
        error.response.data.title,
        error.response.data.message,
        error.response.data.status
      );
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserStatus = async (values) => {
    try {
      setIsLoading(true);
      const result = await UPDATE_USER_STATUS(user.id, values);
      if (result.status === 201) {
        setUser((state) => ({
          ...state,
          ...values,
        }));
        MessageDialog(
          result.data.title,
          result.data.message,
          result.data.status
        );
      }
    } catch (error) {
      MessageDialog(
        error.response.data.title,
        error.response.data.message,
        error.response.data.status
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box p={4}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Box display="flex">
                <Typography variant="h6" fontWeight="bold">
                  Edit Account Details
                </Typography>
                {user?.role && (
                  <Chip
                    label={USER_ROLE[user?.role]}
                    color={
                      user?.role && parseInt(user.role) === 8888
                        ? "secondary"
                        : "primary"
                    }
                    sx={{ fontWeight: "bold", ml: "auto", color: "#fff" }}
                  />
                )}
              </Box>
              <Divider />
              <UserUpdateForm
                user={user}
                handleSubmit={updateUserInformation}
                isLoading={isLoading}
              />
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <BoxTitleContent boxTitle="Manage user">
            <Stack spacing={3}>
              <ChangeRole role={user?.role} handleClick={updateUserStatus} />
              {user?.isBlocked !== undefined && (
                <BlockUser
                  isBlocked={user.isBlocked}
                  handleClick={updateUserStatus}
                />
              )}
              <Button
                onClick={() =>
                  navigate("/admin/user/manage/change-password", {
                    state: { uid: user_id },
                  })
                }
              >
                Change password
              </Button>
            </Stack>
          </BoxTitleContent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserManage;
