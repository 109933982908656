import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Paper,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion } from "framer-motion";
import SecurityIcon from "@mui/icons-material/Security";
import IntegrityIcon from "@mui/icons-material/Verified";
import InnovationIcon from "@mui/icons-material/Lightbulb";
import SupportIcon from "@mui/icons-material/Support";

const companyOverview = {
  title: "About UDS",
  description: [
    "Ulka Digital Security Ltd (UDS) specializes in providing state-of-the-art security solutions for residential, commercial, and public spaces. With a passion for safety and a commitment to customer satisfaction, we have become a trusted partner for clients across Surrey and Langley BC.",
    "Our advanced camera systems and tailored monitoring services are designed to meet the unique security needs of every client, offering peace of mind, 24/7.",
  ],
};

const missionAndVision = [
  {
    title: "Our Mission",
    description:
      "To provide innovative and reliable security solutions that protect lives, property, and peace of mind.",
  },
  {
    title: "Our Vision",
    description:
      "To be the leading security service provider in Surrey and Langley BC, known for exceptional quality, trust, and innovation.",
  },
];

const coreValues = [
  {
    icon: <SecurityIcon />,
    title: "Customer-Centricity",
    desc: "Delivering personalized and effective solutions",
  },
  {
    icon: <IntegrityIcon />,
    title: "Integrity",
    desc: "Building trust through honesty and transparency",
  },
  {
    icon: <InnovationIcon />,
    title: "Innovation",
    desc: "Leveraging cutting-edge technology with precision",
  },
  {
    icon: <SupportIcon />,
    title: "Commitment",
    desc: "Providing 24/7 support and system upgrades",
  },
];

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoading, setImageLoading] = useState(true);

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  const handleImageLoad = () => {
    setTimeout(() => {
      setImageLoading(false);
    }, 500);
  };

  return (
    <div>
      <Container maxWidth="lg">
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h2"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
        >
          About Us
        </Typography>
        {/* Company Overview */}
        <Container maxWidth="lg" sx={{ my: { xs: 4, md: 8 } }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ position: "relative" }}>
                {imageLoading && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={300}
                    animation="wave"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                      borderRadius: 2,
                    }}
                  />
                )}
                <Box
                  component="img"
                  src={require("assets/team.jpg")}
                  alt="UDS Team"
                  onLoad={handleImageLoad}
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: 2,
                    boxShadow: 3,
                    display: "block",
                    mx: "auto",
                    visibility: imageLoading ? "hidden" : "visible",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div {...fadeIn}>
                {companyOverview.description.map((paragraph, index) => (
                  <Typography key={index} paragraph>
                    {paragraph}
                  </Typography>
                ))}
              </motion.div>
            </Grid>
          </Grid>
        </Container>

        {/* Mission & Values */}
        <Box sx={{ bgcolor: "background.paper", py: { xs: 4, md: 8 } }}>
          <Container maxWidth="lg">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Grid container spacing={4}>
                {missionAndVision.map((item, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <Card elevation={3}>
                        <CardContent>
                          <Typography
                            variant={isMobile ? "h6" : "h5"}
                            gutterBottom
                          >
                            {item.title}
                          </Typography>
                          <Typography>{item.description}</Typography>
                        </CardContent>
                      </Card>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>

            {/* Core Values */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <Grid container spacing={3} sx={{ mt: 4 }}>
                {coreValues.map((value, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      whileHover={{ scale: 1.05 }}
                    >
                      <Paper
                        elevation={2}
                        sx={{ p: 2, height: "100%", textAlign: "center" }}
                      >
                        <motion.div
                          whileHover={{ rotate: 360 }}
                          transition={{ duration: 0.5 }}
                        >
                          <IconButton color="secondary" sx={{ mb: 1 }}>
                            {value.icon}
                          </IconButton>
                        </motion.div>
                        <Typography
                          variant={isMobile ? "subtitle1" : "h6"}
                          gutterBottom
                        >
                          {value.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {value.desc}
                        </Typography>
                      </Paper>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </Container>
        </Box>
      </Container>
    </div>
  );
};

export default AboutUs;
