import { AssignmentIndTwoTone } from "@mui/icons-material";
import { Grid } from "@mui/material";
import DashboardOption from "Components/Others/DashboardOption";
import DashboardTopSection from "Components/Others/DashboardTopSection";

const Dashboard = () => {
  return (
    <Grid
      container
      spacing={3}
      sx={{ p: { xs: 0, sm: "0 46px" } }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <DashboardTopSection subTitle="Admin Panel" />
      </Grid>
      <Grid item xs={11} sm={6} md={4}>
        <DashboardOption
          icon={<AssignmentIndTwoTone />}
          title="User"
          path="/admin/user"
          subTitle="Manage and create user"
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
