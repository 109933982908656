import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { motion } from "framer-motion";

const MonitoringSolutions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imagesLoaded, setImagesLoaded] = useState({});

  const sections = [
    {
      title: "24/7 Live Monitoring",
      description:
        "Our dedicated monitoring center provides round-the-clock surveillance of your security systems. Trained professionals actively monitor video feeds, sensor alerts, and system notifications to ensure immediate response to any security concerns. We maintain constant vigilance to protect your property and assets at all hours.",
      imageSrc: require("assets/service/live-monitoring.png"),
      imageAlt: "Live Monitoring Center",
      imageAlternative:
        "Security professionals monitoring multiple screens in a modern control room",
    },
    {
      title: "Smart Alert System",
      description:
        "Leverage our intelligent alert system that combines advanced analytics with real-time monitoring. Receive instant notifications for suspicious activities, unauthorized access attempts, or system anomalies. Our smart filters help minimize false alarms while ensuring you're promptly informed of genuine security events through our mobile app and web interface.",
      imageAlt: "Smart Alerts",
      imageSrc: require("assets/service/smart-alerts.png"),
      imageAlternative:
        "Mobile device displaying security alerts and notification interface",
    },
    {
      title: "Cloud Storage Solutions",
      description:
        "Secure your surveillance footage with our reliable cloud storage solutions. Access historical recordings anytime, anywhere through our encrypted cloud platform. Our storage systems feature automatic backup, easy retrieval, and flexible retention periods to meet your specific security requirements while ensuring data integrity and confidentiality.",
      imageAlt: "Cloud Storage",
      imageSrc: require("assets/service/cloud-storage.png"),
      imageAlternative:
        "Visual representation of secure cloud storage infrastructure for surveillance data",
    },
  ];

  const handleImageLoad = (index) => {
    setImagesLoaded((prev) => ({ ...prev, [index]: true }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
          sx={{ mb: 6 }}
        >
          Monitoring Solutions
        </Typography>
      </motion.div>

      <Grid container spacing={6}>
        {sections.map((section, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Grid
                container
                spacing={4}
                alignItems="center"
                direction={index % 2 === 0 ? "row" : "row-reverse"}
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    color={theme.palette.secondary.main}
                    gutterBottom
                  >
                    {section.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {section.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {!imagesLoaded[index] && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={isMobile ? 300 : 400}
                      sx={{ borderRadius: 2 }}
                    />
                  )}
                  <Box
                    component="img"
                    src={section.imageSrc}
                    alt={section.imageAlt}
                    title={section.imageAlternative}
                    onLoad={() => handleImageLoad(index)}
                    sx={{
                      width: "100%",
                      height: "auto",
                      borderRadius: 2,
                      boxShadow: 3,
                      maxHeight: isMobile ? "300px" : "400px",
                      objectFit: "cover",
                      display: imagesLoaded[index] ? "block" : "none",
                    }}
                  />
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MonitoringSolutions;
