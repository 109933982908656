import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const FooterNavItem = ({ path, name }) => {
  return (
    <Box
      component={Link}
      to={path}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        textDecoration: "none",
        color: "inherit",
        transition: "transform 250ms ease-in-out",
        "&:hover": {
          color: "secondary.light",
          bgcolor: "inherit",
          transition: "transform 500ms ease-in-out",
          transform: "translateY(-2px)",
        },
      }}
    >
      <ArrowForwardIosIcon color="secondary" sx={{ fontSize: 12 }} />
      <Typography sx={{ transition: "inherit", color: "inherit" }}>
        {name}
      </Typography>
    </Box>
  );
};

export default FooterNavItem;
