import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: {},
    isLoading: true,
  },
  reducers: {
    setUser: (state, action) => {
      state.auth = action.payload;
    },
    updateAccessToken: (state, action) => {
      state.auth = { ...state.auth, accessToken: action.payload };
    },
    logOut: (state) => {
      state.auth = {};
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setUser, updateAccessToken, logOut, setIsLoading } =
  authSlice.actions;

export default authSlice.reducer;
