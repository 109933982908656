import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion, AnimatePresence } from "framer-motion";

const CareerModal = ({ openModal, handleCloseModal, selectedJob }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const modalVariants = {
    hidden: { opacity: 0, rotateY: -90 },
    visible: {
      opacity: 1,
      rotateY: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 20,
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      rotateY: 90,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 20,
        duration: 0.5,
      },
    },
  };

  const contentVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { delay: 0.3, duration: 0.3 },
    },
    exit: {
      opacity: 0,
      x: 50,
      transition: { duration: 0.3 },
    },
  };

  return (
    <AnimatePresence mode="wait">
      {openModal && (
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          maxWidth="md"
          fullWidth
          fullScreen={isMobile}
          PaperComponent={motion.div}
          PaperProps={{
            initial: "hidden",
            animate: "visible",
            exit: "exit",
            variants: modalVariants,
            style: { transformOrigin: "center" },
          }}
        >
          {selectedJob && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={contentVariants}
            >
              <DialogTitle
                sx={{
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.secondary.main,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: isMobile ? "flex-start" : "center",
                  }}
                >
                  <Typography variant="h6" component="span">
                    {selectedJob.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: isMobile ? 1 : 0 }}
                  >
                    Deadline: {selectedJob.deadline}
                  </Typography>
                </Box>
              </DialogTitle>
              <DialogContent
                sx={{ backgroundColor: theme.palette.background.default }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {selectedJob.department} • {selectedJob.location} •{" "}
                  {selectedJob.type}
                </Typography>
                <Typography variant="body1" paragraph>
                  {selectedJob.description}
                </Typography>

                <Typography variant="h6" gutterBottom>
                  Requirements:
                </Typography>
                <ul>
                  {selectedJob.requirements.map((req, index) => (
                    <li key={index}>
                      <Typography variant="body1">{req}</Typography>
                    </li>
                  ))}
                </ul>

                <Typography variant="h6" gutterBottom>
                  Responsibilities:
                </Typography>
                <ul>
                  {selectedJob.responsibilities.map((resp, index) => (
                    <li key={index}>
                      <Typography variant="body1">{resp}</Typography>
                    </li>
                  ))}
                </ul>
              </DialogContent>
              <DialogActions
                sx={{ backgroundColor: theme.palette.background.default }}
              >
                <Button
                  onClick={handleCloseModal}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.dark,
                    },
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </motion.div>
          )}
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export default CareerModal;
