import { Card, Container, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ADD_NEW_USER } from "Api/Admin/User";
import { MessageDialog } from "Core/Dialogs/SwalDialogs";
import { useNavigate } from "react-router-dom";
import UserRegistrationForm from "Modules/Common/UserRegistrationForm";

const AddUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const clickSubmit = async (values) => {
    try {
      setIsLoading(true);
      const result = await ADD_NEW_USER(values);
      if (result.status === 201) {
        MessageDialog(
          result.data.title,
          result.data.message,
          result.data.status
        );
        navigate(-1);
      }
    } catch (error) {
      MessageDialog(
        error.response.data.title,
        error.response.data.message,
        error.response.data.status
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 3 }} disableGutters>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h6" fontWeight="bold">
            Add New User
          </Typography>
          <Divider />
          <UserRegistrationForm
            handleSubmit={clickSubmit}
            isLoading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default AddUser;
