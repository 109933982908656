import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
  Button,
  Skeleton,
} from "@mui/material";
import { motion } from "framer-motion";
import ProductModal from "./Components/ProductModal";

const Products = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imageLoading, setImageLoading] = useState({});

  const products = [
    {
      id: 1,
      name: "Waterproof RFID Bluetooth Reader",
      modalName: "ASR2100Z-B",
      description:
        "Dahua's Bluetooth Access Reader is designed for contactless and secure entry management...",
      image: require("assets/products/P1.png"),
      details:
        "Dahua's Bluetooth Access Reader is designed for contactless and secure entry management. With an IP67 rating, it's ready for any situation, ensuring dependable performance both inside and out. With encrypted IC card reading and seamless Bluetooth connectivity, this reader improves your access control. It uses the DHI-ASC3202B Web Access Controller to activate its full Bluetooth capabilities, providing an anti-tampering alarm and user convenience for a superior security experience.",
    },
    {
      id: 2,
      name: "Video Access Controller",
      modalName: "DHI-ASI8213SA-W",
      description:
        "The DHI-ASI8213SA-W is an advanced Video Access Controller with a 2MP HD dual-camera system...",
      image: require("assets/products/P2.png"),
      details:
        "The DHI-ASI8213SA-W is an advanced Video Access Controller with a 2MP HD dual-camera system for superior video quality. This device, which runs on the powerful Android 11 platform, provides a variety of identification options, including cutting-edge facial detection and QR codes access. With its sleek 8-inch touch 2.5D screen designed for seamless interaction, you can navigate with ease. It is tough and ready for any environment, with an IP65 rating for dependable outdoor installation. The device also includes a discretely integrated IC card reader and a full-featured video intercom, combining all of your access control requirements into a single advanced unit.",
    },
    {
      id: 3,
      name: "RFID Fingerprint Reader (V3)",
      modalName: "DHI-ASR1102A (V3)",
      description:
        "As a key part of the access control system, the DHI-ASR1102A-V3 access control card reader supports...",
      image: require("assets/products/P3.png"),
      details:
        "As a key part of the access control system, the DHI-ASR1102A-V3 access control card reader supports card and fingerprint unlock, and sends the information to the access controller for verification. With a slim mullion mountable design, it is applicable to a variety of applications.",
    },
    {
      id: 4,
      name: "Waterproof RFID Reader",
      modalName: "DHI-ASR1100B-V1",
      description:
        "As a key part of the access control system, the DHI-ASR1100B-V1 access control card reader supports card unlock and sends the information to the access controller for verification...",
      image: require("assets/products/P4.png"),
      details:
        "As a key part of the access control system, the DHI-ASR1100B-V1 access control card reader supports card unlock and sends the information to the access controller for verification. With its standard design, it is applicable to a variety of applications",
    },
    {
      id: 5,
      name: "RFID Card Reader",
      modalName: "DHI-ASR1101M-V1",
      description:
        "As a key part of the access control system, the DHI-ASR1101M-V1 access control card reader supports card and password unlock...",
      image: require("assets/products/P5.png"),
      details:
        "As a key part of the access control system, the DHI-ASR1101M-V1 access control card reader supports card and password unlock, and sends the information to the access controller for verification. With a standard design, it is applicable to a variety of applications.",
    },
    {
      id: 6,
      name: "Elevator Control Module",
      modalName: "DHI-VTM416",
      description:
        "The DHI-VTM416 is an elevator control module that can manage permission controls for up to 16 floors...",
      image: require("assets/products/P6.png"),
      details:
        "The DHI-VTM416 is an elevator control module that can manage permission controls for up to 16 floors. The DHI-VTM416 can scale to control up to 128 total floors by cascading 7 additional DHI-VTM416 programs as sub-mode. The module supports the RS-485 interface, two alarm outputs, and 6 alarm inputs. There is also an emergency release via the alarm input to have all floor access unlock in the case of a triggered alarm. The module can summon elevators to the VTO floor when used with a Dahua VTO.",
    },
    {
      id: 7,
      name: "Web Access Controller",
      modalName: "DHI-ASC3202B",
      description:
        "The DHI-ASC3202B provides a single-interface access control solution that is accessible anywhere there is an internet connection...",
      image: require("assets/products/P7.png"),
      details:
        "The DHI-ASC3202B provides a single-interface access control solution that is accessible anywhere there is an internet connection. The controller has two modes: the main mode and the sub mode. The main mode allows the controller to serve as central management for all access control devices in a building or area. The sub mode allows additional controllers to be pooled into the main controller device list and networks card readers together. Each controller controls up to two (2) doors but can be scalable up to 40 doors. The controller can be powered via PoE+ (IEEE802.3at), added to DMSS for remote door control, and ONVIF compliant to allow for third-party reader management.",
    },
    {
      id: 8,
      name: "DSS Pro V8 Video Management System",
      modalName: "DSS Pro V8",
      description:
        "DSS Pro is a comprehensive and expandable Dahua VMS for flexible, scalable, reliable and powerful central management...",
      image: require("assets/products/P8.png"),
      details:
        "DSS Pro is a comprehensive and expandable Dahua VMS for flexible, scalable, reliable and powerful central management. With client-server architecture, DSS Pro provides central management, information sharing, convenient connections, and multi-service cooperation. The software provides basic functions such as user permission management, device management, alarm management, and central storage, but also delivers advanced features of video wall and map configuration, access control features, video intercom, attendance, and analytic functions such as face detection and object detection. It also integrates add-ons such as business intelligence and traffic surveillance.",
    },
    {
      id: 9,
      name: "DSS Express V8 Video Management System",
      modalName: "DSS Express V8",
      description:
        "DSS Express is a flexible Dahua VMS for scalable, reliable and powerful central management...",
      image: require("assets/products/P9.png"),
      details:
        "DSS Express is a flexible Dahua VMS for scalable, reliable and powerful central management. DSS Express provides central management, information sharing, convenient connections, and multi-service cooperation. The software supports up to 256 cameras with a license. DSS Express integrates with access control devices, video intercom terminals and offers analytic functions such as object detection.",
    },
    {
      id: 10,
      name: "Four-door Multi-function Access Controller",
      modalName: "DHI-ASC2204C-S",
      description:
        "The DHI-ASC2204C-S is a four-door multi-function access controller...",
      image: require("assets/products/P10.png"),
      details:
        "The DHI-ASC2204C-S is a four-door multi-function access controller designed for advanced security systems. It supports multiple card types and various access control features, making it suitable for a wide range of applications.",
    },
    {
      id: 11,
      name: "Access Reader and Controller",
      modalName: "DHI-ASI2212H-W",
      description:
        "The DHI-ASI2212H-W is an integrated access reader and controller...",
      image: require("assets/products/P11.png"),
      details:
        "The DHI-ASI2212H-W is an integrated access reader and controller that combines multiple functions in one device. It supports various identification methods including card, password, and fingerprint, providing flexible and secure access control solutions.",
    },
    {
      id: 12,
      name: "IC Card",
      modalName: "IC-S50",
      description:
        "The IC-S50 is a high-frequency smart card for access control systems...",
      image: require("assets/products/P12.png"),
      details:
        "The IC-S50 is a high-frequency smart card designed for use with Dahua access control systems. It offers secure and convenient access control with encrypted data transmission, making it suitable for various security applications.",
    },
    {
      id: 13,
      name: "IC Key Fob",
      modalName: "IC-SM",
      description:
        "The IC-SM is a compact and durable IC key fob for access control...",
      image: require("assets/products/P13.png"),
      details:
        "The IC-SM is a compact and durable IC key fob designed for convenient access control. It uses the same technology as the IC-S50 card but in a more portable form factor, making it ideal for users who prefer a keychain-style credential.",
    },
  ];

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleImageLoad = (productId) => {
    setImageLoading((prev) => ({ ...prev, [productId]: false }));
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        py: { xs: 3, md: 6 },
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant={isMobile ? "h5" : "h3"}
          component="h1"
          align="center"
          gutterBottom
          sx={{
            mb: { xs: 2, md: 4 },
            fontWeight: "bold",
            color: theme.palette.secondary.main,
            textTransform: "uppercase",
          }}
        >
          Our Products
        </Typography>

        <Grid
          container
          spacing={isMobile ? 5 : isTablet ? 5 : 3}
          justifyContent="center"
        >
          {products.map((product, index) => (
            <Grid item xs={10} sm={6} md={4} lg={3} key={product.id}>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: index * 0.08 }}
              >
                <Card
                  sx={{
                    height: isMobile ? 300 : isTablet ? 350 : 400,
                    position: "relative",
                    overflow: "hidden",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      "& .MuiCardContent-root": {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  {imageLoading[product.id] !== false && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                      animation="wave"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                      }}
                    />
                  )}
                  <CardMedia
                    component="img"
                    height="100%"
                    image={product.image}
                    alt={product.name}
                    onLoad={() => handleImageLoad(product.id)}
                    sx={{
                      objectFit: "contain",
                      padding: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      visibility:
                        imageLoading[product.id] !== false
                          ? "hidden"
                          : "visible",
                    }}
                  />
                  <CardContent
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                      opacity: 0,
                      transition: "opacity 0.2s ease-in-out",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: 1.5,
                    }}
                  >
                    <Box>
                      <Typography
                        variant={isMobile ? "subtitle1" : "h6"}
                        component="h2"
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.secondary.main,
                          mb: 0.5,
                        }}
                      >
                        {product.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 0.5,
                          color: "rgba(255, 255, 255, 0.8)",
                          fontSize: "0.8rem",
                        }}
                      >
                        Model: {product.modalName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 1,
                          color: "rgba(255, 255, 255, 0.8)",
                          fontSize: "0.8rem",
                        }}
                      >
                        {product.description}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleOpenModal(product)}
                      sx={{
                        alignSelf: "flex-start",
                        color: theme.palette.secondary.main,
                        borderColor: theme.palette.secondary.main,
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.main,
                          color: theme.palette.common.white,
                        },
                      }}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
      <ProductModal
        open={openModal}
        onClose={handleCloseModal}
        product={selectedProduct}
      />
    </Box>
  );
};

export default Products;
