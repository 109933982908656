import React from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Icon,
} from "@mui/material";
import { motion } from "framer-motion";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SecurityIcon from "@mui/icons-material/Security";
import VideocamIcon from "@mui/icons-material/Videocam";
import SensorsIcon from "@mui/icons-material/Sensors";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShieldIcon from "@mui/icons-material/Shield";
import CameraOutdoorIcon from "@mui/icons-material/CameraOutdoor";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";

const PropertySecurity = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const sections = [
    {
      title: "Property Surveillance Systems",
      description:
        "Secure your property with our comprehensive surveillance solutions. Our advanced systems include high-definition cameras, motion sensors, and perimeter monitoring to protect your premises. Get 24/7 recording and remote viewing capabilities for complete property oversight.",
      icons: [
        ApartmentIcon,
        VideocamIcon,
        SensorsIcon,
        CameraOutdoorIcon,
        NotificationsActiveIcon,
      ],
    },
    {
      title: "Access Control & Monitoring",
      description:
        "Control and monitor access to your property with our advanced security systems. From keycard entry to biometric access control, we provide robust solutions to manage entry points. Track visitor movements and maintain detailed access logs for enhanced security.",
      icons: [LockIcon, DoorSlidingIcon, AccessTimeIcon],
    },
    {
      title: "24/7 Security Management",
      description:
        "Rest easy with our round-the-clock security management services. Our integrated systems provide real-time monitoring, instant alerts, and rapid response protocols. Professional security oversight ensures your property is protected at all times.",
      icons: [
        SecurityIcon,
        ShieldIcon,
        AccessTimeIcon,
        NotificationsActiveIcon,
      ],
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant={isMobile ? "h4" : isTablet ? "h3" : "h2"}
          component="h1"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
          sx={{ mb: { xs: 3, sm: 4, md: 6 } }}
        >
          Property Security Solutions
        </Typography>
      </motion.div>

      <Grid container spacing={{ xs: 4, sm: 5, md: 6 }}>
        {sections.map((section, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Grid
                container
                spacing={{ xs: 2, sm: 3, md: 4 }}
                alignItems="center"
                direction={
                  isMobile ? "column" : index % 2 === 0 ? "row" : "row-reverse"
                }
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    color={theme.palette.secondary.main}
                    gutterBottom
                  >
                    {section.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: { xs: 1, sm: 2 } }}>
                    {section.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { xs: 150, sm: 180, md: 200 },
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    {section.icons.map((IconComponent, iconIndex) => (
                      <Icon
                        key={iconIndex}
                        component={IconComponent}
                        sx={{
                          fontSize: { xs: 40, sm: 50, md: 60 },
                          color: theme.palette.secondary.main,
                          margin: { xs: 1, sm: 1.5, md: 2 },
                        }}
                      />
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PropertySecurity;
