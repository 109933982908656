import moment from 'moment'

export const datetFormat_Short_LLL = (date) => {
    return date && moment(date).format('MMM DD, YYYY hh:mm A')  // 2022-08-26T12:38:49.470Z to Aug 26, 2022 12:38 PM (short month name)
}

export const datetFormat_LLL = (date) => {
    return date && moment(date).format('LLL')  // 2022-08-26T12:38:49.470Z to August 26, 2022 12:38 PM (long month name)
}

export const dfReadableToServerYM = (date) => {
    return moment.utc(date).format('YYYY-MM-DD') // May 26, 2022 to 2022-05-26
}

export const dfReadableToServerYM_Slash = (date) => {
    return date ? moment.utc(date).format('DD/MM/YYYY') : null // May 26, 2022 to 2022-05-26
}