import { useState } from "react";
import {
  Container,
  Typography,
  Stack,
  TextField,
  Card,
  Grid,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { ArrowForward } from "@mui/icons-material";
import { MessageDialog } from "Core/Dialogs/SwalDialogs";
import { useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "Api/Auth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const INITIAL_FORM_STATE = {
    user_name: null,
  };

  const FORM_VALIDATION = Yup.object().shape({
    user_name: Yup.string()
      .required("Required")
      .email("Invalid email address")
      .nullable(),
  });

  const clickSubmit = async (values) => {
    try {
      setIsLoading(true);
      const result = await FORGOT_PASSWORD(values);
      if (result.status === 200 && result.data.data.uid) {
        navigate("/reset-password", {
          state: {
            uid: result.data.data.uid,
            email: result.data.data.email,
          },
          replace: true,
        });
      }
    } catch (error) {
      MessageDialog(
        error.response.data.title,
        error.response.data.message,
        error.response.data.status
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card elevation={2} sx={{ p: 6, width: "100%" }}>
        <Stack spacing={2}>
          <Typography variant="body1">
            Please enter the email address
          </Typography>
          <Formik
            initialValues={{ ...INITIAL_FORM_STATE }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => clickSubmit(values)}
          >
            {({
              setFieldValue,
              values,
              touched,
              errors,
              setTouched,
              submitForm,
            }) => (
              <Form noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      name="user_name"
                      placeholder="Enter email here.."
                      variant="outlined"
                      value={values.user_name}
                      onChange={(e) =>
                        setFieldValue(
                          "user_name",
                          e.target.value ? e.target.value : null
                        )
                      }
                      required
                      fullWidth={true}
                      onBlur={() => setTouched({ ...touched, user_name: true })}
                      error={touched.user_name && errors.user_name}
                      helperText={touched.user_name && errors.user_name}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="end">
                    <LoadingButton
                      loading={isLoading}
                      loadingPosition="end"
                      variant="contained"
                      size="large"
                      sx={{ color: "#fff", ml: "auto" }}
                      endIcon={<ArrowForward />}
                      onClick={() => submitForm()}
                    >
                      Next
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Stack>
      </Card>
    </Container>
  );
};

export default ForgotPassword;
