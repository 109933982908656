import React from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Icon,
} from "@mui/material";
import { motion } from "framer-motion";
import HomeIcon from "@mui/icons-material/Home";
import SecurityIcon from "@mui/icons-material/Security";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import VideocamIcon from "@mui/icons-material/Videocam";
import SensorsIcon from "@mui/icons-material/Sensors";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import CameraIcon from "@mui/icons-material/Camera";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";

const PersonalSecurity = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const sections = [
    {
      title: "Home Security Systems",
      description:
        "Protect your home and loved ones with our comprehensive security solutions. Our advanced home security systems include smart cameras, motion sensors, and access control to keep your property safe. Get real-time alerts and remote monitoring capabilities for complete peace of mind.",
      icons: [
        HomeIcon,
        VideocamIcon,
        SensorsIcon,
        LockIcon,
        NotificationsActiveIcon,
      ],
    },
    {
      title: "Personal Safety Devices",
      description:
        "Stay protected wherever you go with our personal safety devices. From panic buttons to GPS tracking devices, we offer reliable tools that connect you instantly to emergency response services. Our devices are discreet, easy to use, and provide immediate assistance when needed.",
      icons: [SecurityIcon, GpsFixedIcon, NotificationsActiveIcon],
    },
    {
      title: "Mobile Security Solutions",
      description:
        "Access your security systems on-the-go with our mobile solutions. Monitor your cameras, control access points, and receive instant notifications through our user-friendly mobile app. Stay connected to your security system 24/7, ensuring your peace of mind from anywhere.",
      icons: [
        PhoneAndroidIcon,
        CameraIcon,
        DoorSlidingIcon,
        NotificationsActiveIcon,
      ],
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 4, sm: 6, md: 8 } }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant={isMobile ? "h4" : isTablet ? "h3" : "h2"}
          component="h1"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
          sx={{ mb: { xs: 3, sm: 4, md: 6 } }}
        >
          Personal Security Solutions
        </Typography>
      </motion.div>

      <Grid container spacing={{ xs: 3, sm: 4, md: 6 }}>
        {sections.map((section, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Grid
                container
                spacing={{ xs: 2, sm: 3, md: 4 }}
                alignItems="center"
                direction={
                  isMobile ? "column" : index % 2 === 0 ? "row" : "row-reverse"
                }
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant={isMobile ? "h5" : isTablet ? "h4" : "h3"}
                    color={theme.palette.secondary.main}
                    gutterBottom
                  >
                    {section.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: { xs: 1, sm: 2 } }}>
                    {section.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { xs: 150, sm: 180, md: 200 },
                      backgroundColor: theme.palette.background.default,
                      p: { xs: 1, sm: 2 },
                    }}
                  >
                    {section.icons.map((IconComponent, iconIndex) => (
                      <Icon
                        key={iconIndex}
                        component={IconComponent}
                        sx={{
                          fontSize: { xs: 40, sm: 50, md: 60 },
                          color: theme.palette.secondary.main,
                          m: { xs: 1, sm: 1.5, md: 2 },
                        }}
                      />
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PersonalSecurity;
