import { useEffect, useState } from "react";
import { AppBar, Toolbar, Box, Button } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import Footer from "Core/Footer";
import UserDrawer from "Core/AppBar/UserDrawer";
import UserAppbar from "Core/AppBar/UserAppbar";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { setToolbarHeight } from "Services/Redux/settingsSlice";
import Topbar from "Core/Topbar";

const UserLayout = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    dispatch(setToolbarHeight(ref.current.clientHeight));
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box>
        <Topbar />
      </Box>
      <AppBar
        ref={ref}
        position="sticky"
        color="inherit"
        style={{
          boxShadow: "0 5px 15px #c4c4c44d",
          transition: ".32s",
          width: "100%",
        }}
      >
        <Toolbar
          disableGutters
          style={{ padding: isMobile ? "0 10px" : "0 46px" }}
        >
          {isMobile ? <UserDrawer /> : <UserAppbar />}
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, width: "100%", mb: 4 }}>
        <Outlet />
      </Box>
      <Footer />
      {showScrollButton && (
        <Button
          onClick={scrollToTop}
          variant="contained"
          color="secondary"
          sx={{
            position: "fixed",
            bottom: { xs: "80px", md: "55px" },
            right: { xs: "20px", md: "40px" },
            width: { xs: "40px", md: "50px" },
            height: { xs: "40px", md: "50px" },
            minWidth: { xs: "40px", md: "50px" },
            padding: 0,
            borderRadius: 0,
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: { xs: "24px", md: "28px" },
            }}
          />
        </Button>
      )}
    </Box>
  );
};

export default UserLayout;
