import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RoleChecker = ({ allowedRoles }) => {
  const location = useLocation();
  const { auth } = useSelector((state) => state.auth);

  return auth?.user?.role && allowedRoles?.includes(auth.user.role) ? (
    <Outlet />
  ) : auth?.accessToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RoleChecker;
