import { Card, Icon, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DashboardOption = ({ title, subTitle, icon, path }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        p: 4,
        textAlign: "center",
        transition: "transform 500ms ease-in-out",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.02)",
          transition: "transform 250ms ease-in-out",
          boxShadow: `0px 0px 15px rgba(0,0,0,0.2)`,
        },
      }}
      onClick={() => path && navigate(path)}
    >
      <Icon sx={{ width: 35, height: "auto", svg: { fontSize: 35 } }}>
        {icon}
      </Icon>
      <Typography variant="h6" sx={{ letterSpacing: 1.5, lineHeight: 1.5 }}>
        {title}
      </Typography>
      {subTitle && <Typography variant="overline">{subTitle}</Typography>}
    </Card>
  );
};

export default DashboardOption;
