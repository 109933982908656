import { Box, Button, Typography, Link, Grid } from "@mui/material";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import { USER_LIST } from "Api/Admin/User";
import { datetFormat_Short_LLL } from "Helpers/DateFormat";
import { USER_ROLE } from "Helpers/UserRole";
import UserItem from "./Components/UserItem";

const defaultColDef = {
  filter: true,
  floatingFilter: true,
  sortable: true,
  resizable: true,
  flex: 3,
  cellClass: "suppress-movable-col",
  filterParams: {
    suppressAndOrCondition: true,
  },
};

const UserList = () => {
  const navigate = useNavigate();
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalAdmin, setTotalAdmin] = useState(0);

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  const datasource = {
    async getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
      const filterParams = { startRow, endRow, filterModel, sortModel };

      try {
        const result = await USER_LIST(filterParams);

        if (result.status === 200) {
          setTotalStudents(result.data.totalStudents);
          setTotalAdmin(result.data.totalAdmin);
          params.successCallback(result.data.rows, result.data.count);
          if (result.data.count === 0) return params.api.showNoRowsOverlay();
          params.api.hideOverlay();
        }
      } catch (error) {
        return params.failCallback();
      }
    },
  };

  const columns = [
    {
      headerName: "Action",
      field: "id",
      flex: 1,
      minWidth: 120,
      filter: false,
      suppressMenu: true,
      sortable: false,
      cellStyle: { display: "flex", alignItems: "center" },
      cellRendererFramework: (params) => {
        return (
          <Button
            variant="contained"
            disableElevation
            size="small"
            sx={{ borderRadius: 0, color: "#fff" }}
            onClick={() => {
              navigate(`/admin/user/manage`, {
                state: { user_id: params.data.id },
              });
            }}
          >
            Manage
          </Button>
        );
      },
    },
    {
      headerName: "Name",
      field: "full_name",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Mobile",
      field: "mobile",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["male", "female", "others"],
      },
    },
    {
      headerName: "Role",
      field: "role",
      filter: "agSetColumnFilter",
      valueFormatter: (params) => USER_ROLE[params.value],
      filterParams: {
        values: [8888, 1111, 5555],
        valueFormatter: (params) => USER_ROLE[params.value],
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      filter: "agDateColumnFilter",
      resizable: false,
      valueFormatter: (params) =>
        params?.data?.createdAt &&
        datetFormat_Short_LLL(params?.data?.createdAt),
    },
  ];

  return (
    <Box sx={{ pt: 3 }}>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <UserItem title="TOTAL ADMIN" totalValue={totalAdmin} />
        </Grid>
        <Grid item xs={12} md={4}>
          <UserItem title="TOTAL STUDENT" totalValue={totalStudents} />
        </Grid>
      </Grid>
      <Box sx={{ border: "1px solid #ebebeb" }}>
        <Box style={{ padding: 16 }} sx={{ display: "flex" }}>
          <Typography variant="h6" fontWeight="bold" sx={{ mr: "auto" }}>
            USER LIST
          </Typography>
          <Link onClick={() => navigate("/admin/user/add")} underline="none">
            <Button
              variant="contained"
              color="primary"
              sx={{ color: "#fff" }}
              disableElevation
            >
              Add User
            </Button>
          </Link>
        </Box>
        <Box className="ag-theme-alpine" style={{ height: 600 }}>
          <AgGridReact
            columnDefs={columns}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            suppressMenuHide={true}
            rowModelType="serverSide"
            serverSideStoreType="partial"
            sortingOrder={["desc", "asc"]}
            multiSortKey={"ctrl"}
            overlayNoRowsTemplate="No Record Found"
            // loadingCellRenderer={false} // Loading row of grid
            suppressHorizontalScroll={true} // Hide horizontal scrollbar
            pagination={true}
            paginationAutoPageSize={true}
            cacheBlockSize={30}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserList;
