import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { motion } from "framer-motion";

const ConsultationAndSecurityPlanning = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoading, setImageLoading] = useState({});

  const sections = [
    {
      title: "Professional Security Assessment",
      description:
        "Our expert consultants conduct thorough on-site evaluations to assess your security requirements. We analyze potential vulnerabilities, access points, and existing security measures to develop a comprehensive understanding of your property's security needs. This detailed assessment forms the foundation for creating an effective security strategy tailored to your specific situation.",
      imageAlt: "Security Assessment",
      imageSrc: require("assets/service/security-assessment.png"),
      imageAlternative:
        "Security consultant conducting an on-site evaluation with assessment tools",
    },
    {
      title: "Customized Security Planning",
      description:
        "Based on our assessment findings, we develop customized security solutions that align perfectly with your requirements and budget. Our team works closely with you to create detailed security plans that incorporate the most suitable technologies, deployment strategies, and operational procedures. We ensure every recommendation is practical, effective, and scalable to meet your evolving security needs.",
      imageAlt: "Security Planning",
      imageSrc: require("assets/service/security-planning.png"),
      imageAlternative:
        "Team of security experts reviewing and designing custom security plans",
    },
    {
      title: "Implementation Strategy",
      description:
        "We provide comprehensive guidance on implementing your security plan effectively. Our experts outline clear timelines, resource requirements, and integration approaches to ensure smooth deployment. We also offer training recommendations and best practices for maintaining optimal security operations, helping you maximize the value of your security investment.",
      imageAlt: "Implementation Strategy",
      imageSrc: require("assets/service/implementation-strategy.png"),
      imageAlternative:
        "Visual representation of a phased security system implementation plan",
    },
  ];

  const handleImageLoad = (index) => {
    setImageLoading((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          align="center"
          color={theme.palette.secondary.main}
          gutterBottom
          sx={{ mb: 6 }}
        >
          Consultation and Security Planning
        </Typography>
      </motion.div>

      <Grid container spacing={6}>
        {sections.map((section, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Grid
                container
                spacing={4}
                alignItems="center"
                direction={index % 2 === 0 ? "row" : "row-reverse"}
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    color={theme.palette.secondary.main}
                    gutterBottom
                  >
                    {section.title}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {section.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ position: "relative" }}>
                    {(imageLoading[index] === undefined ||
                      imageLoading[index]) && (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={isMobile ? 300 : 400}
                        animation="wave"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1,
                          borderRadius: 2,
                        }}
                      />
                    )}
                    <Box
                      component="img"
                      src={section.imageSrc}
                      alt={section.imageAlt}
                      title={section.imageAlternative}
                      onLoad={() => handleImageLoad(index)}
                      sx={{
                        width: "100%",
                        height: "auto",
                        borderRadius: 2,
                        boxShadow: 3,
                        maxHeight: isMobile ? "300px" : "400px",
                        objectFit: "cover",
                        visibility: imageLoading[index] ? "hidden" : "visible",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ConsultationAndSecurityPlanning;
