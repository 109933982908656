import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import BusinessIcon from "@mui/icons-material/Business";
import SchoolIcon from "@mui/icons-material/School";
import GroupIcon from "@mui/icons-material/Group";

const WhyUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const services = [
    {
      title: "Experienced Team",
      description:
        "Our team of security experts brings years of industry experience.",
      icon: (
        <GroupIcon
          sx={{
            fontSize: isMobile ? 30 : 40,
            color: theme.palette.secondary.main,
          }}
        />
      ),
    },
    {
      title: "Solutions For You",
      description:
        "We tailor our security solutions to meet your specific needs.",
      icon: (
        <BusinessIcon
          sx={{
            fontSize: isMobile ? 30 : 40,
            color: theme.palette.secondary.main,
          }}
        />
      ),
    },
    {
      title: "24/7 Support",
      description:
        "Round-the-clock support to ensure your security is never compromised.",
      icon: (
        <SecurityIcon
          sx={{
            fontSize: isMobile ? 30 : 40,
            color: theme.palette.secondary.main,
          }}
        />
      ),
    },
    {
      title: "Latest Technology",
      description:
        "We leverage cutting-edge technology to provide superior security solutions.",
      icon: (
        <SchoolIcon
          sx={{
            fontSize: isMobile ? 30 : 40,
            color: theme.palette.secondary.main,
          }}
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg">
      <Typography
        variant={isMobile ? "h4" : "h3"}
        component="h2"
        align="center"
        color={theme.palette.secondary.main}
        gutterBottom
      >
        Why Choose Us
      </Typography>
      <Grid container spacing={isMobile ? 2 : 4} sx={{ mt: isMobile ? 2 : 4 }}>
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={3} key={service.title}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{ flexGrow: 1, textAlign: "center", p: isMobile ? 2 : 3 }}
              >
                {service.icon}
                <Typography
                  gutterBottom
                  variant={isMobile ? "h6" : "h5"}
                  component="h2"
                  sx={{ mt: isMobile ? 1 : 2 }}
                >
                  {service.title}
                </Typography>
                <Typography variant={isMobile ? "body2" : "body1"}>
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WhyUs;
