import { useEffect } from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import Footer from "Core/Footer";
import AdminDrawer from "Core/AppBar/AdminDrawer";
import AdminAppbar from "Core/AppBar/AdminAppbar";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { setToolbarHeight } from "Services/Redux/settingsSlice";
import Topbar from "Core/Topbar";

const AdminLayout = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(setToolbarHeight(ref.current.clientHeight));
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box>
        <Topbar />
      </Box>
      <AppBar
        ref={ref}
        position="sticky"
        color="inherit"
        style={{
          boxShadow: "0 5px 15px #c4c4c44d",
          transition: ".32s",
          width: "100%",
        }}
      >
        <Toolbar
          disableGutters
          style={{ padding: isMobile ? "0 10px" : "0 46px" }}
        >
          {isMobile ? <AdminDrawer /> : <AdminAppbar />}
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, width: "100%", mb: 4 }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default AdminLayout;
