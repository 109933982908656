import swal from "sweetalert2";

// For Information (return promise so we can fire any action after close button click like after buying course reload page)
export const MessageDialog = async (title, text, status) => {
  return await swal.fire({
    title: title,
    text: text,
    icon: status,
    confirmButtonText: "CLOSE",
  });
};

// For delete
export const ConfirmDialog = async (callBack, ptext) => {
  return swal
    .fire({
      title: "Are you sure?",
      text: ptext ? ptext : "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
};
