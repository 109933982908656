import { Box, Stack, IconButton, useMediaQuery, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  ADDRESS,
  SUPPORT_NUMBER,
  SUPPPORT_NUMBER_WHATSAPP,
  FACEBOOK_URL,
  TWITTER_URL,
  LINKEDIN_URL,
  INSTAGRAM_URL,
  BUSINESS_HOURS,
} from "const";

const Topbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  if (isMobile) {
    return null;
  }

  return (
    <Box sx={{ paddingY: 1, width: "100%", bgcolor: "background.paper" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          px: { sm: 4, md: 6 },
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <LocationOnIcon color="secondary" sx={{ fontSize: 20 }} />
            <Box
              component="span"
              sx={{ fontSize: { sm: "0.9rem", md: "1rem" } }}
            >
              {ADDRESS}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AccessTimeIcon color="secondary" sx={{ fontSize: 20 }} />
            <Box
              component="span"
              sx={{ fontSize: { sm: "0.9rem", md: "1rem" } }}
            >
              {BUSINESS_HOURS}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isTablet ? "column" : "row",
            alignItems: isTablet ? "flex-start" : "center",
            gap: 2,
          }}
        >
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              color="secondary"
              href={FACEBOOK_URL}
              target="_blank"
            >
              <FacebookIcon color="secondary" />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              href={TWITTER_URL}
              target="_blank"
            >
              <TwitterIcon color="secondary" />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              href={LINKEDIN_URL}
              target="_blank"
            >
              <LinkedInIcon color="secondary" />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              href={INSTAGRAM_URL}
              target="_blank"
            >
              <InstagramIcon color="secondary" />
            </IconButton>
          </Stack>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PhoneIcon color="secondary" sx={{ fontSize: 20 }} />
            <Box
              component="span"
              sx={{ fontSize: { sm: "0.9rem", md: "1rem" } }}
            >
              {SUPPORT_NUMBER}
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <WhatsAppIcon color="secondary" sx={{ fontSize: 20 }} />
            <Box
              component="span"
              sx={{ fontSize: { sm: "0.9rem", md: "1rem" } }}
            >
              {SUPPPORT_NUMBER_WHATSAPP}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
