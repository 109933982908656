import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";

const ProductModal = ({ open, onClose, product }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoading, setImageLoading] = useState(true);

  if (!product) return null;

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.background.default,
          color: theme.palette.secondary.main,
          fontWeight: "bold",
        }}
      >
        {product.name}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: 3,
            py: 2,
          }}
        >
          <Box
            sx={{
              flex: "0 0 40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            {imageLoading && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={300}
                animation="wave"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
            )}
            <img
              src={product.image}
              alt={product.name}
              onLoad={handleImageLoad}
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "contain",
                visibility: imageLoading ? "hidden" : "visible",
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.text.secondary, mb: 2 }}
            >
              {product.description}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.secondary.main, mb: 2 }}
            >
              Model: {product.modalName}
            </Typography>

            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.primary }}
            >
              {product.details}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductModal;
