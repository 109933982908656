import { Link, MenuItem, Menu } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const NavItem = ({ path, name, children }) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (children) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isChildActive =
    children && children.some((child) => child.path === location.pathname);

  return (
    <>
      <MenuItem
        component={children ? "div" : Link}
        href={children ? undefined : path}
        onClick={handleClick}
        disableRipple
        sx={{
          color:
            location.pathname === path || isChildActive
              ? "secondary.main"
              : "text.secondary",
          bgcolor: "transparent",
          textTransform: "none",
          fontWeight: "bold",
          transition: "transform 250ms ease-in-out",
          "&:hover": {
            color: "secondary.light",
            bgcolor: "inherit",
            transition: "transform 500ms ease-in-out",
            transform: "translateY(-2px)",
          },
        }}
      >
        {name}
      </MenuItem>
      {children && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {children.map((child) => (
            <MenuItem
              key={child.name}
              component={Link}
              href={child.path}
              onClick={handleClose}
              sx={{
                color:
                  location.pathname === child.path
                    ? "secondary.main"
                    : "text.secondary",
                bgcolor: "transparent",
                transition: "transform 250ms ease-in-out",
                "&:hover": {
                  color: "secondary.light",
                  bgcolor: "transparent",
                  transition: "transform 500ms ease-in-out",
                  transform: "translateY(-2px)",
                },
              }}
            >
              {child.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default NavItem;
