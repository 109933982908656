import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { ADDRESS } from "const";

const GoogleMap = () => {
  return (
    <Paper elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
      <Typography
        variant="h6"
        sx={{
          p: 2,
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
        }}
      >
        Our Location
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: { xs: "300px", sm: "400px", md: "500px" },
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            boxShadow: "inset 0 0 10px rgba(0,0,0,0.1)",
            pointerEvents: "none",
          },
        }}
      >
        <iframe
          title="Office Location"
          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.0475018064897!2d-122.7654413!3d49.1766597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d7d0c5c0d4c9%3A0x5f10f56f8d33c0f6!2s${encodeURIComponent(
            ADDRESS
          )}!5e0!3m2!1sen!2s!4v1689612345678!5m2!1sen!2s`}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>
    </Paper>
  );
};

export default GoogleMap;
