import { createTheme } from '@mui/material/styles'

export const DefaultTheme = createTheme({
    palette: {
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
        },
        primary: {
            main: '#0097a7',
            light: '#00bcd4',
            dark: '#006064',
        },
        // primary: {
        //     main: '#f44336',
        //     light: '#ff7961',
        //     dark: '#ba000d',
        // },
        // secondary: {
        //     main: '#4caf50',
        //     light: '#80e27e',
        //     dark: '#087f23',
        // },
        secondary: {
            main: '#e72a26',
            light: '#ff6551',
            dark: '#ac0000',
        },
        text: {
            primary: '#1F1F1F',
            secondary: '#1F1F1F',
        }
    },
    typography: {
        allVariants: {
            // color: "#757575",
            color: "#1F1F1F",
            fontFamily: '"Inter", "Helvetica Neue", "Arial", "sans-serif"'
            // fontFamily: '"Poppins", "Helvetica Neue", "Arial", "sans-serif"'
        },
        // fontFamily: "Poppins"
        fontFamily: [
            'Poppins',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(',')
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            bp750: 750,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
})