import React, { Fragment, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { REFRESH_TOKEN } from "Api/Auth";
import { logOut, setUser, setIsLoading } from "Services/Redux/authSlice";
import { setPersisted } from "Services/Redux/settingsSlice";
import Loading from "Core/Loading";

const PersistLogin = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const isMounted = useRef();
  const { persisted } = useSelector((state) => state.settings);

  useEffect(() => {
    if (isMounted.current) return;
    const checkAuth = async () => {
      try {
        const response = await REFRESH_TOKEN();
        dispatch(setUser(response.data));
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(logOut());
          dispatch(setPersisted(false));
        }
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    persisted ? checkAuth() : dispatch(setIsLoading(false));
    isMounted.current = true;
  }, [dispatch, persisted]);

  return <Fragment>{auth?.isLoading ? <Loading /> : <Outlet />}</Fragment>;
};

export default PersistLogin;
