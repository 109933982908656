import { Card, Divider, Container, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { CHANGE_PASSWORD } from 'Api/Admin/User'
import { useLocation } from 'react-router-dom'
import { MessageDialog } from 'Core/Dialogs/SwalDialogs'

const ChangePassword = () => {
    const { state } = useLocation()
    const uid = state?.uid
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const INITIAL_FORM_STATE = {
        new_password: '',
        confirm_password: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        new_password: Yup.string()
            .required('Required')
            .nullable(),
        confirm_password: Yup.string()
            .required('Required')
            .nullable()
            .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    })

    const handlePasswordChange = async (values) => {
        try {
            setIsLoading(true)
            const result = await CHANGE_PASSWORD(uid, values)
            if (result.status === 201) {
                MessageDialog(result.data.title, result.data.message, result.data.status)
            }
        } catch (error) {
            MessageDialog(error.response.data.title, error.response.data.message, error.response.data.status)
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <Container maxWidth='sm' sx={{ minHeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card elevation={2} sx={{ p: 6, width: '100%' }}>
                <Stack spacing={2}>
                    <Typography fontWeight='bold' variant="h5">Change password</Typography>
                    <Divider />
                    <Formik
                        initialValues={{ ...INITIAL_FORM_STATE }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={values => handlePasswordChange(values)}>
                        {({ setFieldValue, values, touched, errors, submitForm }) => (
                            <Form noValidate>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="new_password"
                                            placeholder='New Password'
                                            required
                                            fullWidth={true}
                                            variant='outlined'
                                            value={values.new_password}
                                            onChange={(e) => setFieldValue('new_password', e.target.value ? e.target.value : null)}
                                            error={touched.new_password && errors.new_password}
                                            helperText={touched.new_password && errors.new_password}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="confirm_password"
                                            placeholder='Confirm Password'
                                            required
                                            fullWidth={true}
                                            variant='outlined'
                                            value={values.confirm_password}
                                            onChange={(e) => setFieldValue('confirm_password', e.target.value ? e.target.value : null)}
                                            error={touched.confirm_password && errors.confirm_password}
                                            helperText={touched.confirm_password && errors.confirm_password}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign='end'>
                                        <LoadingButton
                                            loading={isLoading}
                                            loadingPosition="end"
                                            variant='contained'
                                            size='large'
                                            sx={{ color: '#fff', ml: 'auto' }}
                                            endIcon={<ArrowForward />}
                                            onClick={() => submitForm()}
                                        >Submit
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Stack>
            </Card>
        </Container>
    )
}

export default ChangePassword