import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "Services/Redux/authSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LOGIN } from "Api/Auth";
import { LoadingButton } from "@mui/lab";
import { setPersisted } from "Services/Redux/settingsSlice";
import { MessageDialog } from "Core/Dialogs/SwalDialogs";

const LogIn = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const INITIAL_FORM_STATE = {
    username: "01.mahadi@gmail.com",
    password: "12345678",
  };

  const FORM_VALIDATION = Yup.object().shape({
    username: Yup.string().required("Required").nullable(),
    password: Yup.string().required("Required").nullable(),
  });

  const clickSubmit = async (values) => {
    try {
      setIsLoading(true);
      const result = await LOGIN(values);
      if (result.status === 200) {
        dispatch(setUser(result.data.data));
        dispatch(setPersisted(true));
        navigate(state?.from?.pathname || "/dashboard", { replace: true });
      }
    } catch (error) {
      MessageDialog("Error", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          clickSubmit(values);
        }}
      >
        {({
          setFieldValue,
          values,
          touched,
          errors,
          setTouched,
          submitForm,
        }) => (
          <Form noValidate>
            <Stack spacing={0} sx={{ pt: 5 }}>
              <Typography
                variant="h4"
                textAlign="center"
                fontWeight="bold"
                sx={{ pb: 3 }}
              >
                LOG IN
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="username"
                    label="Email"
                    placeholder="Email"
                    required
                    autoComplete="username"
                    fullWidth={true}
                    variant="outlined"
                    value={values.username}
                    onChange={(e) =>
                      setFieldValue(
                        "username",
                        e.target.value ? e.target.value : null
                      )
                    }
                    onBlur={() => setTouched({ ...touched, username: true })}
                    error={touched.username && errors.username}
                    helperText={touched.username && errors.username}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    label="Password"
                    placeholder="Password"
                    required
                    autoComplete="new-password"
                    fullWidth={true}
                    variant="outlined"
                    value={values.password}
                    onChange={(e) =>
                      setFieldValue(
                        "password",
                        e.target.value ? e.target.value : null
                      )
                    }
                    onBlur={() => setTouched({ ...touched, password: true })}
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" sx={{ pb: 2 }}>
                  <LoadingButton
                    disableElevation
                    fullWidth
                    loading={isLoading}
                    loadingPosition="end"
                    variant="contained"
                    size="large"
                    sx={{ color: "#fff", ml: "auto" }}
                    endIcon={<ArrowForward />}
                    onClick={() => submitForm()}
                  >
                    Sign in
                  </LoadingButton>
                </Grid>
              </Grid>
              <Typography sx={{ alignSelf: "center" }}>
                <Link
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  to={{ pathname: "/forgot-password" }}
                >
                  Forgot password?
                </Link>
              </Typography>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LogIn;
