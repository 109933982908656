import React from "react";
import {
  Box,
  Stack,
  Button,
  Menu,
  MenuItem,
  Fade,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Logo from "Core/AppBar/Components/Logo";
import NavItem from "Core/AppBar/Components/NavItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion, AnimatePresence } from "framer-motion";

const UserAppbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentMenu, setCurrentMenu] = React.useState(null);
  const location = useLocation();

  const handleClick = (event, menuName) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menuName);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  const navItems = [
    { name: "HOME", path: "/" },
    {
      name: "SERVICES",
      children: [
        { name: "Camera Installation", path: "/camera-installation" },
        { name: "Monitoring Solutions", path: "/monitoring-solutions" },
        { name: "Maintenance & Support", path: "/maintenance-support" },
        {
          name: "Consultation & Security Planning",
          path: "/consultation-security-planning",
        },
      ],
    },
    { name: "PRODUCTS", path: "/products" },
    { name: "CAREER", path: "/career" },
    {
      name: "ABOUT",
      children: [
        { name: "About Us", path: "/about-us" },
        { name: "Our Team", path: "/our-team" },
      ],
    },
  ];

  const isActive = (item) => {
    if (item.path) {
      return location.pathname === item.path;
    }
    if (item.children) {
      return item.children.some((child) => location.pathname === child.path);
    }
    return false;
  };

  return (
    <Box
      display="flex"
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Logo />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          height: "100%",
        }}
      >
        <Stack direction="row">
          {navItems.map((item) => (
            <React.Fragment key={item.name}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                {item.children ? (
                  <Button
                    onClick={(e) => handleClick(e, item.name)}
                    endIcon={
                      <motion.div
                        initial={false}
                        animate={{
                          rotateX: currentMenu === item.name ? 180 : 0,
                        }}
                        transition={{ duration: 0.3 }}
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <ExpandMoreIcon
                          sx={{
                            color: isActive(item)
                              ? "secondary.main"
                              : "text.primary",
                            marginLeft: "0px",
                          }}
                        />
                      </motion.div>
                    }
                    sx={{
                      color: isActive(item) ? "secondary.main" : "text.primary",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.00)",
                        color: "secondary.main",
                      },
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      sx={{
                        color: isActive(item)
                          ? "secondary.main"
                          : "text.primary",
                        "&:hover": {
                          color: "secondary.main",
                        },
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Button>
                ) : (
                  <NavItem
                    name={item.name}
                    path={item.path}
                    isActive={isActive(item)}
                  />
                )}
              </motion.div>
              <AnimatePresence>
                {item.children && currentMenu === item.name && (
                  <Menu
                    anchorEl={anchorEl}
                    open={currentMenu === item.name}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {item.children.map((child, index) => (
                      <motion.div
                        key={child.name}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2, delay: index * 0.1 }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to={child.path}
                          sx={{
                            color:
                              location.pathname === child.path
                                ? "secondary.main"
                                : "text.primary",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                              color: "secondary.main",
                            },
                          }}
                        >
                          {child.name}
                        </MenuItem>
                      </motion.div>
                    ))}
                  </Menu>
                )}
              </AnimatePresence>
            </React.Fragment>
          ))}
        </Stack>

        <Button
          component={Link}
          to="/contact-us"
          variant="contained"
          autoCapitalize="false"
          endIcon={<ArrowForwardIcon />}
          sx={{
            backgroundColor: "secondary.main",
            color: "white",
            borderRadius: 0,
            height: "100%",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "secondary.dark",
            },
          }}
        >
          Contact us
        </Button>
      </Box>
    </Box>
  );
};

export default UserAppbar;
