import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AxiosInterceptor from "Services/AxiosInterceptor";
import UserRoutes from "Modules/User/Routes";
import AdminRoutes from "Modules/Admin/Routes";
import PersistLogin from "Services/PersistLogin";

const router = createBrowserRouter([
  {
    element: <AxiosInterceptor />,
    children: [
      {
        element: <PersistLogin />,
        children: [
          {
            path: "/*",
            element: <UserRoutes />,
          },
          {
            path: "/admin/*",
            element: <AdminRoutes />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
