import UserOption from "Core/AppBar/Components/UserOption";
import { Divider } from "@mui/material";
import { Fragment } from "react";
import { Key, AccountCircle, Logout } from "@mui/icons-material";
import { MessageDialog } from "Core/Dialogs/SwalDialogs";
import { LOG_OUT } from "Api/Auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeDrawer, setPersisted } from "Services/Redux/settingsSlice";
import { logOut } from "Services/Redux/authSlice";

const AdminUserOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const result = await LOG_OUT();
      if (result.status === 200) {
        dispatch(logOut());
        dispatch(setPersisted(false));
        dispatch(closeDrawer());
        navigate("/");
      }
    } catch (error) {
      MessageDialog(
        error.response.data.title,
        "Failed to log out!",
        error.response.data.status
      );
    }
  };

  return (
    <Fragment>
      <UserOption
        name="Profile"
        icon={<AccountCircle fontSize="small" color="secondary" />}
        path="/admin/profile"
      />
      <UserOption
        name="Change Password"
        icon={<Key fontSize="small" color="secondary" />}
        path="/admin/change-password"
      />
      <Divider />
      <UserOption
        name="Logout"
        icon={<Logout fontSize="small" color="secondary" />}
        onClick={handleLogout}
      />
    </Fragment>
  );
};

export default AdminUserOptions;
