import { Box, Typography } from '@mui/material'

const BoxTitleContent = ({ boxTitle, children, additionStyle = {} }) => {
    return (
        <Box sx={{ border: '1px solid #e0e0e0', p: 0 }}>
            <Box sx={{ backgroundColor: '#eeeeee', pl: 5, py: 1 }}>
                <Typography variant='h6' color='textSecondary' sx={{ fontWeight: 'bold', color: '#0E0E0E' }}>{boxTitle}</Typography>
            </Box>
            <Box sx={{ px: 5, py: 2, ...additionStyle }}>
                {children}
            </Box>
        </Box>
    )
}

export default BoxTitleContent