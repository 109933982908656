import {
  Box,
  Card,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import UserProfileForm from "Modules/Common/UserProfileForm";
import { useEffect, useState } from "react";
import { UPDATE_USER, USER } from "Api/User/User";
import { MessageDialog } from "Core/Dialogs/SwalDialogs";

const Profile = () => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await USER();
        setUser(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [isLoading]);

  const UPDATE_USER_INFO = async (values) => {
    try {
      setIsLoading(true);
      const result = await UPDATE_USER(values);
      if (result.status === 201) {
        setUser((state) => ({
          ...state,
          ...values,
        }));
        MessageDialog(
          result.data.title,
          result.data.message,
          result.data.status
        );
      }
    } catch (error) {
      MessageDialog(
        error.response.data.title,
        error.response.data.message,
        error.response.data.status
      );
    } finally {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 3 }} disableGutters>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Box display="flex">
            <Typography variant="h6" fontWeight="bold">
              Edit Account Details
            </Typography>
          </Box>
          <Divider />

          <UserProfileForm
            user={user}
            handleSubmit={UPDATE_USER_INFO}
            isLoading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default Profile;
