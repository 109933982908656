import { axiosPrivateInstance } from "Services/AxiosService";

export const ADD_NEW_USER = async (user) =>
  axiosPrivateInstance.post(`/bsf/user/add`, JSON.stringify(user));

export const UPDATE_USER_INFO = async (uid, values) =>
  axiosPrivateInstance.put(
    `/bsf/user/update-user-info/${uid}`,
    JSON.stringify(values)
  );

export const UPDATE_USER_STATUS = async (uid, values) =>
  axiosPrivateInstance.put(
    `/bsf/user/update-user-status/${uid}`,
    JSON.stringify(values)
  );

export const CHANGE_PASSWORD = async (uid, values) =>
  axiosPrivateInstance.put(
    `/bsf/user/change-password/${uid}`,
    JSON.stringify(values)
  );

export const GET_USER = async (uid) =>
  axiosPrivateInstance.get(`/bsf/user/get-user/${uid}`);

export const USER_LIST = async (filterParams) =>
  axiosPrivateInstance.post(
    `/bsf/user/get-user-list`,
    JSON.stringify(filterParams)
  );
