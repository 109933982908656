import { MenuOpen } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import Logo from "Core/AppBar/Components/Logo";
import NavItem from "Core/AppBar/Components/NavItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "Services/Hooks/useAuth";
import AdminUserOptions from "Core/AppBar/UserOptions/AdminUserOptions";
import { toggleDrawer, closeDrawer } from "Services/Redux/settingsSlice";

const AdminDrawer = ({ window }) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { isDrawerOpen } = useSelector((state) => state.settings);
  const isAuth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box display="flex" sx={{ flexGrow: 1 }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => dispatch(toggleDrawer())}
        sx={{ mr: "auto" }}
      >
        <MenuOpen />
      </IconButton>

      <Logo />

      <Drawer
        anchor="left"
        container={container}
        variant="temporary"
        open={isDrawerOpen}
        onClose={() => dispatch(closeDrawer())}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 260,
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "text.disabled",
              borderRadius: 16,
            },
          },
        }}
      >
        <Stack sx={{ p: 2 }} spacing={1}>
          <Typography
            sx={{ fontWeight: "bold", color: "text.secondary", paddingTop: 2 }}
          >
            Menu
          </Typography>
          <Divider />
          <Stack>
            <NavItem name="Dashboard" path="/admin" />
            <NavItem name="User" path="/admin/user" />
            <NavItem name="Career" path="/admin/career" />
          </Stack>

          <Divider />

          {isAuth ? (
            <Fragment>
              <Typography sx={{ fontWeight: "bold", color: "text.secondary" }}>
                Account Manage
              </Typography>
              <Divider />
              <AdminUserOptions />
            </Fragment>
          ) : (
            <Button
              onClick={() => {
                dispatch(closeDrawer());
                navigate("/login");
              }}
              variant="outlined"
            >
              Login
            </Button>
          )}
        </Stack>
      </Drawer>
    </Box>
  );
};

export default AdminDrawer;
