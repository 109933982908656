import { axiosPrivateInstance } from "Services/AxiosService";
import { useDispatch } from "react-redux";
import { logOut, setUser } from "Services/Redux/authSlice";
import { Outlet, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import { setPersisted } from "Services/Redux/settingsSlice";
import store from "Services/Redux/store";
import { REFRESH_TOKEN } from "Api/Auth";

const AxiosInterceptor = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  axiosPrivateInstance.interceptors.request.use(
    (config) => {
      if (
        !config.headers["Authorization"] &&
        store?.getState()?.auth?.auth?.accessToken
      ) {
        config.headers["Authorization"] = `Bearer ${
          store.getState().auth.auth.accessToken
        }`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosPrivateInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 403 && !prevRequest?.sent) {
        prevRequest.sent = true;
        try {
          const response = await REFRESH_TOKEN();
          const newAccessToken = response.data.accessToken;
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          dispatch(setUser(true));
          return axiosPrivateInstance(prevRequest);
        } catch (err) {
          if (err.response.status === 401) {
            dispatch(logOut());
            dispatch(setPersisted(false));
            return Promise.reject(error);
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return <Outlet />;
};

export default AxiosInterceptor;
