import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import { CssBaseline } from "@mui/material";
////========THEME
import { ThemeProvider } from "@mui/material/styles";
import { DefaultTheme } from "Helpers/Theme";
////========REDUX
import store from "Services/Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StrictMode>
);
