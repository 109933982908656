export const USER_ROLE = {
    8888: 'Admin',
    5555: 'Instructor',
    1111: 'Student'
}

export const USER_ROLE_REVERSE = {
    'admin': 8888,
    'instuctor': 5555,
    'student': 1111
}