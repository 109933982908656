import { Link, ListItemIcon, MenuItem } from '@mui/material'
import { useLocation } from 'react-router-dom'

const UserOption = ({ name, icon, path, onClick }) => {
    const location = useLocation()
    return (
        <MenuItem
            component={Link}
            href={path}
            onClick={onClick} // Only For LogOut
            sx={{
                color:
                    location.pathname === path
                        ? "secondary.main"
                        : "text.secondary",
                bgcolor: 'inherit',
                textTransform: 'none',
                fontWeight: 'bold',
                transition: 'transform 250ms ease-in-out',
                '&:hover': {
                    color: 'secondary.main',
                    bgcolor: 'inherit',
                    transition: 'transform 500ms ease-in-out',
                    transform: 'translateY(-2px)',
                }
            }}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            {name}
        </MenuItem >
    )
}

export default UserOption